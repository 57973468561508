<template>
  <div class="row">
    <div class="col-12 col-sm-12 px-0">
      <div class="accordion" role="tablist">
        <b-card no-body class="mb-1 px-0 py-0">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-navbar-toggle target="top-spending-users" class="title-card">
              <template #default="{ expanded }">
                <span>Top Spending Users</span>
                <i class="far fa-angle-up" v-if="expanded"></i>
                <i class="far fa-angle-down" v-else></i>
              </template>
            </b-navbar-toggle>
          </b-card-header>
          <b-collapse
            id="top-spending-users"
            visible
            accordion="top-spending-users"
            role="tabpanel"
          >
            <b-card-body>
              <div class="row">
                <div class="col-12 col-sm-12">
                  <grid-loader
                    class="expertel-loader"
                    color="#ff502d"
                    :loading="loading"
                    :size="20"
                    sizeUnit="px"
                  ></grid-loader>
                </div>
              </div>
              <div v-if="data_completed === true">
                <div class="accordion" role="tablist">
                  <div v-for="(line, key) in lines.slice(0, 5)" :key="key">
                    <table class="dashboard-table">
                      <thead v-if="key == 0">
                        <tr>
                          <th>User</th>
                          <th class="text-center d-none d-md-table-cell">
                            Carrier
                          </th>
                          <th class="col-wb"></th>
                          <th class="text-center">Total</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            {{ line.name }} <br /><strong>{{
                              line.phone | phoneNumber
                            }}</strong>
                          </td>
                          <td class="text-center d-none d-md-table-cell">
                            <img
                              :src="
                                `https://api.expertel.ca/dist/images/${line.carrier}.svg`
                              "
                              class="img-fluid carrier-img"
                            />
                          </td>
                          <td class="col-wb">
                            <b-progress
                              :value="line.relative"
                              class="w-75"
                            ></b-progress>
                          </td>
                          <td class="text-center slim-info-td">
                            {{ line.usage }}
                          </td>
                          <td class="text-right">
                            <b-navbar-toggle
                              :target="`collapse-tdu-${key}`"
                              @click="getTopSpending(line.phone, key)"
                            >
                              <template #default="{ expanded }">
                                <i
                                  v-if="expanded"
                                  class="fas fa-times color-orange ml-3"
                                ></i>
                                <i
                                  v-else
                                  class="fas fa-chart-bar color-orange ml-3"
                                ></i>
                              </template>
                            </b-navbar-toggle>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <b-collapse
                      :id="`collapse-tdu-${key}`"
                      class="top-modal"
                      accordion="topsu-accordion"
                      role="tabpanel"
                    >
                      <!--  {{ line.name }} -->
                      <div v-if="line.collapsed">
                        <h2 class="color-white">
                          Cost Factors
                        </h2>
                        <hr />
                        <div class="row">
                          <div class="col-12 col-sm-12">
                            <grid-loader
                              class="expertel-loader"
                              color="#ff502d"
                              :loading="loading_modal"
                              :size="20"
                              sizeUnit="px"
                              
                            ></grid-loader>
                          </div>
                        </div>
                        <div class="row" v-if="loading_modal == false" >
                          <div
                            class="col-12 col-sm-12 min-width4 fontsmall-chart"
                          >
                            <highcharts
                              ref="costSpending"
                              class="optimizerChart top-spending-chart"
                              :options="lines_accordeon"
                              :callback="resizeChart()"

                              v-if="monthly_data.data.length > 0 && !is_trial"
                            >
                            </highcharts>
                            <img src="@/assets/free-trial.jpg" alt="IMG" style="max-width: 100%;"
                              v-if="loading_modal == false && is_trial &&  monthly_data.data.length == 0"
                            />
                          </div>
                        </div>
                      </div>
                    </b-collapse>
                  </div>
                </div>
                <p class="mt-3 mb-0 text-right">
                  <a
                    :href="reportMeta.csv_url"
                    target="_blank"
                    class="link mr-3"
                    ><strong>Download CSV</strong></a
                  >
                  <button class="link" v-b-modal.modalTTSUS>
                    <strong>View All</strong>
                  </button>
                </p>
              </div>
            </b-card-body>
          </b-collapse>
        </b-card>
      </div>
    </div>

    <!-- [[ MODAL ]] -->
    <b-modal id="modalTTSUS" size="xl" centered scrollable class="card">
      <template #modal-header="{ close }">
        <h3 class="font-circular-book mx-auto">Top Spending Users</h3>
        <!-- Emulate built in modal header close button action -->
        <b-button size="sm" variant="outline-expertel" @click="close()">
          <i class="fas fa-times    "></i>
        </b-button>
      </template>
      <div class="row">
        <div class="col-12 col-sm-12">
          <div v-for="(line, key) in lines" :key="key">
            <table class="dashboard-table">
              <thead v-if="key == 0">
                <tr>
                  <th>User</th>
                  <th class="text-center d-none d-md-table-cell">Carrier</th>
                  <th class="col-wb"></th>
                  <th class="text-center">Total</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    {{ line.name }} <br /><strong>{{
                      line.phone | phoneNumber
                    }}</strong>
                  </td>
                  <td class="text-center d-none d-md-table-cell">
                    <img
                      :src="
                        `https://api.expertel.ca/dist/images/${line.carrier}.svg`
                      "
                      class="img-fluid carrier-img"
                    />
                  </td>
                  <td class="col-wb">
                    <b-progress
                      :value="line.relative"
                      class="w-75"
                    ></b-progress>
                  </td>
                  <td class="text-center">
                    <strong>{{ line.usage }}</strong>
                  </td>
                  <td class="text-right">
                    <b-navbar-toggle
                      :target="`collapse-tdu-modal-${key}`"
                      @click="getTopSpending(line.phone, key)"
                    >
                      <template #default="{ expanded }">
                        <i
                          v-if="expanded"
                          class="fas fa-times color-orange ml-3"
                        ></i>
                        <i
                          v-else
                          class="fas fa-chart-bar color-orange ml-3"
                        ></i>
                      </template>
                    </b-navbar-toggle>
                  </td>
                </tr>
              </tbody>
            </table>
            <b-collapse
              :id="`collapse-tdu-modal-${key}`"
              class="top-modal"
              accordion="topsu-accordion"
              role="tabpanel"
            >
              <div v-if="line.collapsed">
                <h2 class="color-white">{{ line.name }} Cost Factors
                </h2>
                <hr />
                <div class="row">
                  <div class="col-12 col-sm-12">
                    <grid-loader
                      class="expertel-loader"
                      color="#ff502d"
                      :loading="loading_modal"
                      :size="20"
                      sizeUnit="px"
                    ></grid-loader>
                  </div>
                </div>
                <div class="row mt-4" v-if="loading_modal == false">
                  <div class="col-12 col-sm-12">
                    <highcharts
                      class="optimizerChart top-spending-chart"
                      :options="lines_accordeon"
                      :callback="resizeChart()"
                      v-if="monthly_data.data.length > 0 && !is_trial"
                    >
                  </highcharts>
                    <img src="@/assets/free-trial.jpg" alt="IMG" style="max-width: 100%;"
                      v-if="loading_modal == false && is_trial &&   monthly_data.data.length == 0"
                    />
                  </div>
                </div>
              </div>
            </b-collapse>
          </div>
        </div>
      </div>
      <template #modal-footer="{ close }">
        <div class="w-100">
          <button
            aria-label="Close"
            type="button"
            class="float-right button-expertel"
            @click="close()"
          >
            Close
          </button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: ["login_data", "id_report"],
  data() {
    return {
      lines: [],
      lines_accordeon: {},
      monthly_data: [],
      data_user: [],
      data_completed: false,
      loading: true,
      loading_modal: true,
      reportMeta: [],
      is_trial: false,  
    };
  },
  watch: {
    id_report: function(newVal) {
      this.getData(newVal);
    }
  },
  created() {
    if (this.id_report !== "") {
      this.getData(this.id_report);
    }
  },
  methods: {
    async getData(id_report) {
      const self = this;
      self.is_trial = self.login_data.client.is_trial
      await this.axios({
        method: "get",
        url: `https://api.expertel.ca/api/v1/client/me/reports/${id_report}/data?type=top_cost`,
        responseType: "json",
        headers: {
          Authorization: "Bearer " + self.login_data.token
        }
      })
        .then(function(response) {
          self.reportMeta = response.data.meta;
          response.data.data.forEach(data => {
            self.lines.push({
              phone: data.phone,
              name: data.name,
              carrier: data.carrier,
              usage: data.usage,
              relative: data.relative,
              extra_details_url: data.extra_details_url,
              collapsed: false
            });
          });
          self.data_completed = true;
          self.loading = false;
          //sort((a, b) => a.relative - b.relative)
        })
        .catch(error => (console.log(error), alert(error)));
    },
    async getTopSpending(phone, key) {
      //alert()
      const self = this;

      if (this.lines[key].collapsed == false) {
        this.lines.forEach(data => {
          if (data.collapsed == true) {
            data.collapsed = false;
          }
        });
        this.lines[key].collapsed = !this.lines[key].collapsed;

        this.loading_modal = true;
        await this.axios({
          method: "get",
          url: `https://api.expertel.ca/api/v1/client/me/reports/${this.id_report}/data?type=cost_factors&phone=${phone}`,
          responseType: "json",
          headers: {
            Authorization: "Bearer " + self.login_data.token
          }
        })
          .then(function(response) {
            self.monthly_data = response.data;
            self.loading_modal = false;
          })
          .catch(error => (console.log(error), alert(error)));

        let dataUser = [];
        let total_amount = 0;

        this.monthly_data.data.forEach(data => {
          total_amount += data.amount;
          dataUser.push({
            name: data.cost_factor,
            y: data.amount
          });
        });
        this.data_user = dataUser;

        this.lines_accordeon = {
          chart: {
            type: "pie",
            backgroundColor: "transparent",
            height: "250px"
          },
          credits: {
            enabled: false
          },
          legend: {
            layout: "vertical",
            align: "right",
            verticalAlign: "middle",
            itemStyle: {
              color: "#fff",
              fontWeight: "bold",
              fontSize: "13px"
            },
            labelFormatter: function() {
              return (
                '<span style="color: #FFFFFF">' +
                this.name +
                " / $" +
                this.y +
                " / " +
                ((this.y * 100) / total_amount).toFixed(2) +
                "%</span>"
              );
            }
          },
          colors: [
            "#ff502d",
            "#67F44A",
            "#7E79EF",
            "#4CEAF2",
            "#BAC61E",
            "#D300FF"
          ],
          title: {
            text: ""
          },
          plotOptions: {
            pie: {
              allowPointSelect: true,
              cursor: "pointer",
              borderColor: null,
              dataLabels: {
                enabled: false
              },
              showInLegend: true
            }
          },
          series: [
            {
              minPointSize: 10,
              innerSize: "70%",
              zMin: 0,
              name: "",
              data: dataUser
            }
          ],
          responsive: {
            rules: [
              {
                condition: {
                  maxWidth: 300
                },
                chartOptions: {
                  legend: {
                    align: "center",
                    verticalAlign: "bottom",
                    layout: "horizontal"
                  }
                }
              }
            ]
          }
        };
      } else {
        this.lines[key].collapsed = !this.lines[key].collapsed;
      }
    },
    resizeChart() {
      if (this.lines_accordeon.series.data !== undefined) {
        this.lines_accordeon.series.data = this.dataUser;
      }
    }
  }
};
</script>
