<template>
  <div
    class="card bg-white"
    :class="{
      'justify-content-center': loading || stockOptions.length <= 0,
      '': loading == false
    }"
  >
    <div class="row" v-if="loading == false">
      <div class="col-12 col-sm-12">
        <div
          class="title-button-blue float-left"
          v-if="optimizer_data.length !== 0"
        >
        <!-- No traial account -->
          <router-link to="/optimizer" v-if="!this.is_trial" >
            Optimizer
          </router-link>

          <!-- Trail Account -->
          <a
            href="#"
            @click.prevent="showMkwModal()"
            
            v-if="this.is_trial"
          >
            Optimizer
          </a>

          <span class="content-info-tooltip">
            <i id="optrimizer-info" class="fal fa-info-circle openTooltip"></i>
            <b-tooltip target="optrimizer-info" placement="right">
              Optimizer identifies opportunities to save and presents
              recommended actions you can take to start saving today. Act fast
              on recommended optimization to save the most money.
            </b-tooltip>
          </span>
        </div>
      </div>
    </div>
    <div class="row" v-if="loading == true">
      <div class="col-12 col-sm-12">
        <grid-loader
          class="expertel-loader"
          color="#ff502d"
          :loading="loading"
          :size="20"
          sizeUnit="px"
        ></grid-loader>
      </div>
    </div>
    <div
      class="row py-3 align-items-center"
      v-if="loading == false && optimizer_data.length !== 0"
    >
      <div class="col-12 col-sm-12">
        <p class="font-16 line-height-20">
          You could save
          <strong>
            {{ optimizer_data.optimizer.savings.replace("-", "") }}</strong
          >
          this month.
        </p>
      </div>
    </div>

    <div
      class="row py-3 align-items-center"
      v-if="loading == false && stockOptions.length !== 0"
    >
      <div class="col-12 col-sm-12">
        <highcharts class="optimizerChart" :options="stockOptions"></highcharts>
      </div>
    </div>

    <div
      class="row py-3 align-items-center"
      v-if="loading == false && optimizer_data.length !== 0"
    >
      <div class="col-12 col-sm-12">
        <!-- No Trial Account -->
        <router-link
          to="/optimizer"
          class="button-expertel btn-h48 d-flex align-items-center justify-content-center"
          v-if="!this.is_trial"
        >
          VIEW MORE
        </router-link>

        <!-- Trial Account -->
        <a
          href="#"
          @click.prevent="showMkwModal()"
          class="button-expertel btn-h48 d-flex align-items-center justify-content-center"
          v-if="this.is_trial"
        >
          VIEW MORE
        </a>

      </div>
      
    </div>

    <div class="row py-3 align-items-center" v-if="noPermit !== ''">
      <div class="col-12 col-sm-12">
        <p class="font-16 line-height-20 text-center px-4">
          <strong>{{ noPermit }}</strong>
        </p>
      </div>
    </div>


    <!-- [[ UNASSIGNED LINES MODAL ]] -->
    <transition name="fade">
      <div
        id="modal-contact"
        class="modal-expertel container-fluid"
        v-if="show_mkw === true"
      >
        <div class="modal-container container-500">
          <div class="header-modal">
            <button class="close-modal" @click="showMkwModal()">
              <i class="fa fa-times" aria-hidden="true"></i>
            </button>
          </div>
          <div class="body-modal modal-market-watch">
            <div class="justify-content-center">
              <img src="@/assets/img/mkw.svg" alt="IMG" />
              <h2 class="justify-content-center">Subscribe to Expertel iQ</h2>
              <p class="text-center">
                To view these results, please contact your account executive to subscribe to Expertel iQ
              </p>
              <button
                  class="button-expertel"
                  @click="showMkwModal()"
                >
                  CLOSE
              </button>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <!-- [[ UNASSIGNED LINES MODAL ]] -->

  </div>
</template>

<script>
// import func from "vue-editor-bridge";
export default {
  props: ["login_data", "id_report"],
  data() {
    return {
      optimizer_data: [],
      optimize_line_counts: "",
      stockOptions: [],
      loading: true,
      noPermit: "",
      is_trial: false,
      show_mkw: false,
    };
  },
  watch: {
    login_data: function(newVal) {
      this.getData(newVal);
    }
  },
  created() {
    if (!Array.isArray(this.login_data)) {
      this.getData();
    }
  },
  methods: {
    getData() {
      const self = this;
      self.is_trial = self.login_data.client.is_trial
      //console.log(self.login_data);
      if(this.id_report)
      {
        this.loading = true;
        this.axios({
          method: "get",
          url: `https://api.expertel.ca/api/v1/client/me/reports/${this.id_report}/data?type=optimizer_summary`,
          responseType: "json",
          headers: {
            Authorization: "Bearer " + self.login_data.token
          }
        })
          .then(function(response) {
            self.optimizer_data = response.data.data;
            self.optimize_line_counts = response.data.data.optimizer.num_items;
            self.loading = false;
            // ORDER DATA ---> .sort( (a, b) => b.percentage - a.percentage);

            self.stockOptions = {
              chart: { type: "solidgauge", height: "250px" },
              credits: {
                enabled: false
              },
              title: {
                text: "",
                style: {
                  fontSize: "24px"
                }
              },
              tooltip: {
                enabled: false
              },
              pane: {
                startAngle: 0,
                endAngle: 360,
                background: [
                  {
                    // Track for Move
                    outerRadius: "100%",
                    innerRadius: "75%",
                    backgroundColor: "#dae2f2",
                    borderWidth: 0
                  }
                ]
              },
              yAxis: {
                min: 0,
                max: 100,
                lineWidth: 0,
                tickPositions: []
              },
              plotOptions: {
                solidgauge: {
                  dataLabels: {
                    format: "{point.y}%",
                    backgroundColor: "transparent",
                    borderWidth: 0,
                    color: "#343f63",
                    enabled: true,
                    style: {
                      fontFamily: "'Circular', sans-serif",
                      fontSize: "40px"
                    },
                    verticalAlign: "top",
                    y: -30,
                    x: 0
                  },
                  linecap: "round",
                  stickyTracking: false,
                  rounded: true
                }
              },
              series: [
                {
                  name: "",
                  data: [
                    {
                      color: "#ff502d",
                      radius: "100%",
                      innerRadius: "75%",
                      y: response.data.data.optimizer.saving_percentage
                    }
                  ]
                }
              ]
            };
          })
          .catch(function(error) {
            if (
              error.response.status !== undefined &&
              error.response.status == 403
            ) {
              self.noPermit =
                "You need to upgrade your billing plan to see this feature.";
              self.loading = false;
            } else {
              self.makeToast("ERROR", error, "danger");
            }
          });
      }else{
        this.loading = false;
      }
    },
    showMkwModal() {
      this.show_mkw = !this.show_mkw;
    },
    makeToast(message, variant = null) {
      this.$bvToast.toast(`${message}`, {
        title: "Error",
        autoHideDelay: 5000,
        appendToast: true,
        variant: variant
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.optimizerChart {
  width: 100%;
  margin: 0 auto;
}
.optimizeInfo {
  i {
    &::before {
      color: #25b715;
    }
  }
}
</style>
