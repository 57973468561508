<template>
  <div class="card line-count-overview">
    <div class="row">
      <div
        class="col-12 col-sm-12 d-flex flex-column flex-md-row justify-content-between"
      >
        <h2>
          Current Data Usage
          <h2 class="mb-0 ml-2" v-if="complete !== false">
            - {{ currentDataUsage.data[0].date }}
          </h2>

          <i class="fas fa-info-circle ml-2" id="tt-current-data-usage"></i>

          <b-tooltip target="tt-current-data-usage" placement="right">
            <p class="text-left color-white px-2 py-2 mb-0">
              Current Data Usage displays near real-time data about your total
              current data usage along with projected data use for this billing
              cycle.
            </p>
          </b-tooltip>
        </h2>

        <select
          v-model="indexData"
          class="mt-4 mt-md-0"
          v-if="complete !== false"
        >
          <option
            v-for="(data, index) in currentDataUsage.data"
            :key="index"
            :value="index"
            >{{ getNameProvider(data.provider_id) }} -
            <span v-if="data.account_id">{{ data.account_id }}</span
            ><span v-else>Main</span></option
          >
        </select>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-sm-12">
        <grid-loader
          class="expertel-loader"
          color="#ff502d"
          :loading="loading"
          :size="20"
          sizeUnit="px"
        ></grid-loader>
      </div>
    </div>
    <div class="row" v-if="complete !== false">
      <div
        class="col-12 col-sm-12 col-md-6 col-lg-2 mt-4 text-center container-data-container br-md-1"
      >
        <div class="row">
          <div class="col-12 col-sm-12 data-container">
            <p class="title">CURRENT TOTAL USAGE (GB)</p>
            <p class="cost">
              {{ currentDataUsage.data[indexData].current_usage }}
            </p>
          </div>
        </div>
        <p class="mb-0 alert-message d-lg-none">
          <strong>{{
            currentDataUsage.data[indexData].current_usage_percentage
          }}</strong>
          usage of pool
        </p>
      </div>
      <div
        class="col-12 col-sm-12 col-md-6 col-lg-2 mt-4 text-center container-data-container br-lg-1"
      >
        <div class="row">
          <div class="col-12 col-sm-12 data-container">
            <p class="title">TOTAL DATA POOL SIZE (GB)</p>
            <p class="cost">{{ currentDataUsage.data[indexData].data_pool }}</p>
          </div>
        </div>
      </div>
      <div
        class="col-12 col-sm-12 col-md-6 col-lg-2 mt-4 text-center container-data-container br-md-1"
      >
        <div class="row">
          <div class="col-12 col-sm-12 data-container ">
            <p class="title">DAYS LEFT IN BILLING CYCLE</p>
            <p class="cost">{{ currentDataUsage.data[indexData].days_left }}</p>
          </div>
        </div>
      </div>
      <div
        class="col-12 col-sm-12 col-md-6 col-lg-2 mt-4 text-center container-data-container br-lg-1"
      >
        <div class="row">
          <div class="col-12 col-sm-12 data-container ">
            <p class="title">PROJECTED USAGE BY THE END OF THE CYCLE (GB)</p>
            <p class="cost">
              {{ currentDataUsage.data[indexData].projected_usage }}
            </p>
          </div>
        </div>
      </div>
      <div
        class="col-12 col-sm-12 col-md-6 col-lg-2 mt-4 text-center container-data-container br-md-1"
      >
        <div class="row">
          <div class="col-12 col-sm-12 data-container ">
            <p class="title">PROJECTED OVERAGE (GB)</p>
            <p class="cost">
              {{ currentDataUsage.data[indexData].projected_overage }}
            </p>
          </div>
        </div>
        <p class="mb-0 alert-message d-lg-none">
          <strong
            >Updated on
            {{ currentDataUsage.data[indexData].current_date }}</strong
          >
        </p>
      </div>
      <div
        class="col-12 col-sm-12 col-md-6 col-lg-2 mt-4 text-center container-data-container"
      >
        <div class="row">
          <div class="col-12 col-sm-12 data-container">
            <p class="title">PROJECTED OVERAGE COST</p>
            <p class="cost">
              {{ currentDataUsage.data[indexData].projected_cost }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="row d-none d-lg-flex" v-if="complete !== false">
      <div class="col-12 col-sm-12 col-md-6 col-lg-2 mt-2 text-center">
        <p class="mb-0 alert-message">
          <strong>{{
            currentDataUsage.data[indexData].current_usage_percentage
          }}</strong>
          usage of pool
        </p>
      </div>
      <div class="col-12 col-sm-12 col-md-6 col-lg-2 mt-2 text-center"></div>
      <div class="col-12 col-sm-12 col-md-6 col-lg-2 mt-2 text-center"></div>
      <div class="col-12 col-sm-12 col-md-6 col-lg-2 mt-2 text-center"></div>
      <div class="col-12 col-sm-12 col-md-6 col-lg-2 mt-2 text-center">
        <p class="mb-0 alert-message">
          <strong
            >Updated on
            {{ currentDataUsage.data[indexData].current_date }}</strong
          >
        </p>
      </div>
      <div class="col-12 col-sm-12 col-md-6 col-lg-2 mt-2 text-center"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["id_report", "login_data"],
  data() {
    return {
      providers: [],
      currentDataUsage: [],
      complete: false,
      loading: true,
      indexData: 0
    };
  },
  created() {
    if (this.id_report !== "") {
      this.getData(this.id_report);
    }
  },
  watch: {
    id_report: function(newVal) {
      this.getData(newVal);
    }
  },
  methods: {
    async getData(id_report) {
      const self = this;

      await this.axios({
        method: "get",
        url: `https://api.expertel.ca/api/v1/client/me/reports/${id_report}/data?type=live_data`,
        responseType: "json",
        headers: {
          Authorization: "Bearer " + self.login_data.token
        }
      })
        .then(function(response) {
          self.getProviders(response.data);
          self.complete = true;
          self.loading = false;
        })
        .catch(error => (console.log(error), alert(error)));
    },

    async getProviders(information) {
      const self = this;
      await this.axios({
        method: "get",
        url: `https://api.expertel.ca/api/v1/providers`,
        responseType: "json",
        headers: {
          Authorization: "Bearer " + self.login_data.token
        }
      })
        .then(function(response_prov) {
          self.providers = response_prov.data;

          //Assign the data of accounts
          self.currentDataUsage = information;
          self.currentDataUsage.data[
            self.indexData
          ].current_usage = self.currentDataUsage.data[
            self.indexData
          ].current_usage
            .toString()
            .replace("GB", "");
          self.currentDataUsage.data[
            self.indexData
          ].data_pool = self.currentDataUsage.data[self.indexData].data_pool
            .toString()
            .replace("GB", "");
          self.currentDataUsage.data[
            self.indexData
          ].projected_usage = self.currentDataUsage.data[
            self.indexData
          ].projected_usage
            .toString()
            .replace("GB", "");
          self.currentDataUsage.data[
            self.indexData
          ].projected_overage = self.currentDataUsage.data[
            self.indexData
          ].projected_overage
            .toString()
            .replace("GB", "");
        })
        .catch(error => (console.log(error), alert(error)));
    },

    getNameProvider(provider_id) {
      const self = this;

      let name = "";
      if (provider_id !== "") {
        self.providers.forEach(data => {
          if (parseInt(data.id) === parseInt(provider_id)) {
            name = data.name;
          }
        });
      }
      return name;
    }
  }
};
</script>

<style lang="scss" scoped>
.line-count-overview {
  .title {
    font-size: 12px;
    line-height: 14px;
    margin-bottom: 0;
  }
  .cost {
    font-family: "Circular", sans-serif;
    font-weight: bold;
    font-size: 36px;
  }
  .alert-message {
    color: #ff502d;
    margin-bottom: 0;
    i {
      margin-right: 0.5rem;
    }
  }
  .br-0 {
    border-right: 0;
  }
  .container-data-container {
    display: flex;
    .data-container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
}

@media screen and (max-width: 1440px) {
  .line-count-overview {
    .title {
      letter-spacing: 0;
    }
    .cost {
      font-size: 36px;
    }
  }
}
@media screen and (max-width: 1439px) and (min-width: 991px) {
  .line-count-overview {
    .title {
      letter-spacing: 0;
    }
    .cost {
      font-size: 36px;
    }
  }
}
@media screen and (max-width: 991px) {
  .line-count-overview {
    .container-data-container {
      display: block;
    }
  }
}
@media screen and (max-width: 767px) {
  .line-count-overview {
    .title {
      letter-spacing: 0;
    }
    .cost {
      font-size: 28px;
    }
    .container-data-container {
      display: block;
    }
  }
}
</style>
