<template>
  <div class="card bg-white">
    <div class="row">
      <div class="col-12 col-sm-12">
        <div class="title-button-blue">
          <a @click.prevent="showMkwModal()">
            MarketWatch
          </a>
          <span>
            <i
              id="market-watch-info"
              class="fal fa-info-circle openTooltip"
            ></i>
            <b-tooltip target="market-watch-info" placement="right">
              MarketWatch compares your company's wireless spend with other
              Expertel iQ customers of a similar size.
            </b-tooltip>
          </span>
        </div>
      </div>
    </div>
    <div class="row py-3">
      <div class="col-12 col-sm-12">
        <p class="font-16 line-height-20">
          <!-- You overpaid <strong>$154.70</strong> -->
          <strong>{{ title_status }}</strong>
        </p>
      </div>
    </div>

    <div class="row py-3">
      <div class="col-12 col-sm-12">
        <highcharts
          class="marketWatchChart"
          :options="generateChart()"
        ></highcharts>
      </div>
    </div>
    <div class="row py-3">
      <div class="col-12 col-sm-12">
        <a
          href="#"
          @click.prevent="showMkwModal()"
          class="button-expertel btn-h48 d-flex align-items-center justify-content-center"
        >
          VIEW MORE
        </a>
      </div>
    </div>
    <!-- [[ UNASSIGNED LINES MODAL ]] -->
    <transition name="fade">
      <div
        id="modal-contact"
        class="modal-expertel container-fluid"
        v-if="show_mkw === true"
      >
        <div class="modal-container container-500">
          <div class="header-modal">
            <button class="close-modal" @click="showMkwModal()">
              <i class="fa fa-times" aria-hidden="true"></i>
            </button>
          </div>
          <div class="body-modal modal-market-watch">
            <div class="justify-content-center">
              <img src="@/assets/img/mkw.svg" alt="IMG" />
              <h2 class="justify-content-center">MarketWatch</h2>
              <p class="text-center">
                Please fill out the information below to have a team member
                contacting you to learn how to lower the rates on your account
              </p>
              <form
                action=""
                class="d-flex flex-column text-left"
                ref="wmContactForm"
              >
                <input
                  type="text"
                  class="form-control"
                  placeholder="Phone"
                  name="mwPhone"
                  @input="contactedByPhone"
                  pattern="[0-9]+"
                  v-on:keypress="isNumber(event)"
                  maxlength="10"
                />

                <input
                  type="text"
                  class="form-control"
                  placeholder="Email"
                  name="mwEmail"
                  @input="contactedByEmail"
                />

                <button
                  class="button-expertel"
                  @click.prevent="sendContactRequest()"
                >
                  REQUEST
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <!-- [[ UNASSIGNED LINES MODAL ]] -->
  </div>
</template>

<script>
export default {
  props: ["login_data", "id_report"],
  data() {
    return {
      show_mkw: false,
      market_watch_data: [],
      title_status: "",
      phone: "",
      email: "",
      //sendByEmail: false,
      user_info: [],
      level_status: ""
    };
  },
  async created() {
    const self = this;

    if (this.login_data !== undefined || this.login_data.success === false) {
      if (!this.login_data.success) {
        this.$router.push("/");
      }
    } else {
      this.$router.push("/");
    }

    if(self.id_report){
      //GET THE COST CENTRE DATA AND SAVE IT IN [manager_centres]
      await this.axios({
        method: "get",
        url: `https://api.expertel.ca/api/v1/client/me/reports/${self.id_report}/data?type=optimizer_summary`,
        responseType: "json",
        headers: {
          Authorization: "Bearer " + self.login_data.token
        }
      })
        .then(async function(response) {
          self.market_watch_data = response.data;

          self.level_status = self.market_watch_data.data.marketwatch.level;
        })
        .catch(error => this.makeToast("Error", error, "danger"));
    }

    //Get info data user
    this.axios({
      method: "get",
      url: `https://api.expertel.ca/api/v1/client/me/info`,
      responseType: "json",
      headers: {
        Authorization: "Bearer " + self.login_data.token
      }
    })
      .then(function(response) {
        self.user_info = response.data;
        self.loading = false;
      })
      .catch(error => this.makeToast("Error", error, "danger"));
  },
  methods: {
    showMkwModal() {
      this.show_mkw = !this.show_mkw;
    },
    contactedByPhone: function($event) {
      this.phone = $event.target.value;
      // Go update other inputs here
    },
    contactedByEmail: function($event) {
      this.email = $event.target.value;
    },
    isNumber: function(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    sendContactRequest() {
      const self = this;

      //FROMDATA TO SEND
      let formData = new FormData();

      if (this.phone != "") {
        formData.append("phone_number", self.phone);
      }
      if (this.email != "") {
        formData.append("email", self.email);
      }
      if (this.phone == "" && this.email == "") {
        self.makeToast("Error", "Select an option to be contacted", "danger");
      } else {
        //All Ok

        this.loading = true;
        formData.append("type", "marketwatch");
        this.axios({
          method: "post",
          url: `https://api.expertel.ca/api/v1/client/me/contact_request`,
          responseType: "json",
          data: formData,
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + self.login_data.token
          }
        })
          .then(function(response) {
            if (response.data.success == false) {
              self.makeToast(response.data.message, "danger");
              self.loading = false;
            } else {
              self.makeToast(
                "Success",
                "Your request has been successfully submitted. You'll be contacted shortly.",
                "success"
              );
              self.phone = "";
              self.email = "";
              self.$refs.wmContactForm.reset(); //Clean the form
              self.loading = false;
            }
          })
          .catch(
            error => self.makeToast("ERROR", error, "danger"),
            (self.loading = false)
          );
      }
    },

    makeToast(title, message, variant = null) {
      this.$bvToast.toast(`${message}`, {
        title: title,
        autoHideDelay: 5000,
        appendToast: true,
        variant: variant
      });
    },

    generateChart() {
      let level = this.level_status;
      let companyValue = 0;
      let marketValue = 0;
      switch (level) {
        case 1:
          companyValue = 49;
          marketValue = 51;
          this.title_status = "All Good";
          break;
        case 2:
          companyValue = 60;
          marketValue = 50;
          this.title_status = "All Good";
          break;
        default:
          companyValue = 80;
          marketValue = 50;
          this.title_status = "Overpaying";
      }

      let stockOptions = {
        chart: {
          type: "column",
          height: "180px"
        },
        credits: {
          enabled: false
        },
        title: {
          text: ""
        },
        tooltip: {
          enabled: true,
          formatter: function() {
            return "<strong>" + this.point.options.name + "</strong>";
          }
        },
        legend: {
          enabled: false
        },

        yAxis: [
          {
            className: "highcharts-color-0",
            title: {
              text: "$",
              rotation: 180
            },
            labels: {
              enabled: false
            }
          }
        ],

        xAxis: {
          categories: ["Your Company", "Market"]
        },
        plotOptions: {
          column: {
            borderRadius: 5,

            label: {
              enabled: false
            }
          }
        },
        series: [
          {
            data: [
              {
                color: "rgba(239, 81, 53, 0.7)",
                y: companyValue,
                name: "Your Company"
              },
              {
                color: "#ff502d",
                y: marketValue,
                name: "Market"
              }
            ]
          }
        ],

        responsive: {
          rules: [
            {
              condition: {
                minWidth: 300
              },
              chartOptions: {
                chart: {
                  height: "250px"
                }
              }
            }
          ]
        }
      };
      return stockOptions;
    }
  }
};
</script>

<style lang="scss" scoped>
.marketWatchChart {
  width: 100%;
  margin: 0 auto;
}
</style>
