<template>
  <div class="card">
    <div class="row">
      <div class="col-12 col-sm-12">
        <h2>Data Usage</h2>
      </div>
      <div class="col-12 col-sm-12"></div>
    </div>
    <div class="row">
      <div class="col-12 col-sm-12">
        <grid-loader
          class="expertel-loader"
          color="#ff502d"
          :loading="loading"
          :size="20"
          sizeUnit="px"
        ></grid-loader>
      </div>
    </div>
    <div class="row mt-4" v-if="loading == false">
      <div class="col-12 col-sm-12">
        <highcharts class="optimizerChart" :options="stockOptions"></highcharts>
      </div>
    </div>
    <div class="row mt-24px">
      <div class="col-12 col-sm-12 text-right">
        <p class="mb-0">
          <button class="link" v-b-modal.dataUSG>
            <strong>Show more</strong>
          </button>
        </p>
      </div>
    </div>

    <!-- [[ MODAL ]] -->
    <b-modal id="dataUSG" size="xl" centered>
      <template #modal-header="{ close }">
        <h3 class="font-circular-book mx-auto">Data Usage</h3>
        <!-- Emulate built in modal header close button action -->
        <b-button size="sm" variant="outline-expertel" @click="close()">
          <i class="fas fa-times    "></i>
        </b-button>
      </template>
      <div class="row">
        <div class="col-12 col-sm-12">
          <highcharts class="optimizerChart" :options="modalGraph"></highcharts>
        </div>
      </div>
      <template #modal-footer="{ close }">
        <div class="w-100">
          <button
            aria-label="Close"
            type="button"
            class="float-right button-expertel"
            @click="close()"
          >
            Close
          </button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: ["id_report", "login_data"],
  data() {
    return {
      loading: true,
      stockOptions: {},
      modalGraph: {}
    };
  },
  created() {
    if (this.id_report !== "") {
      this.getData(this.id_report);
    }
  },
  watch: {
    id_report: function(newVal) {
      this.getData(newVal);
    }
  },
  methods: {
    async getData(id_report) {
      const self = this;
      await this.axios({
        method: "get",
        url: `https://api.expertel.ca/api/v1/client/me/reports/${id_report}/data?type=data_usage`,
        responseType: "json",
        headers: {
          Authorization: "Bearer " + self.login_data.token
        }
      })
        .then(function(response) {
          self.totalSpend = response.data;
          self.loading = false;
        })
        .catch(error => (console.log(error), alert(error)));

      let dataUser = [],
        dataProm = [],
        catTT = [];
      this.totalSpend.data.forEach(data => {
        catTT.push(data[0]);
        dataUser.push(data[2]);
        dataProm.push(data[1]);
      });

      this.stockOptions = {
        chart: { type: "areaspline" },
        credits: {
          enabled: false
        },
        title: { text: "" },
        legend: { enabled: true },
        xAxis: {
          categories: catTT.slice(catTT.length - 6, catTT.length),
          tickmarkPlacement: "on",
          title: {
            enabled: false
          },
          labels: {
            y: 30,
            style: {
              fontFamily: "'Circular Book', sans-serif",
              fontSize: "1.35em",
              color: "#343f63"
            }
          }
        },
        yAxis: {
          lineWidth: 1,
          labels: {
            enabled: true,
            formatter() {
              return (
                "<span>" +
                this.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
                " GB</span>"
              );
            },
            style: {
              fontFamily: "'Circular Book', sans-serif",
              fontSize: "1.25em",
              color: "#343f63"
            }
          },
          title: {
            enabled: false
          }
        },
        tooltip: {
          formatter() {
            return `<strong>${this.point.y
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} GB</strong>`;
          }
        },
        /*
        series: [
          {
            name: "Company Usage",
            tooltip: {
              split: true
            },
            data: dataProm.slice(dataProm.length - 6, dataProm.length),
            lineWidth: 3,
            marker: {
              symbol: "circle",
              radius: 5
            },
            color: "#ff502d",
            fillColor: "rgba(255, 93, 93, 0.5)"
          },
          {
            name: "Data Pool Size",
            tooltip: {
              split: true
            },
            data: dataUser.slice(dataUser.length - 6, dataUser.length),
            lineWidth: 4,
            marker: {
              symbol: "circle",
              radius: 6
            },
            color: "#343f63",
            fillColor: "rgba(64, 143, 222, 0.6)"
          }
        ]
        */
       series: [
          {
            name: "Data Pool Size",
            tooltip: {
              split: true
            },
            //data: dataProm.slice(dataProm.length - 6, dataProm.length),
            data: dataUser.slice(dataUser.length - 6, dataUser.length),
            lineWidth: 3,
            marker: {
              symbol: "circle",
              radius: 5
            },
            //color: "#343f63",
            color: "#ff502d",
            fillColor: "rgba(64, 143, 222, 0.6)"
          },
          {
            name: "Company Usage",
            tooltip: {
              split: true
            },
            //data: dataUser.slice(dataUser.length - 6, dataUser.length),
            data: dataProm.slice(dataProm.length - 6, dataProm.length),
            lineWidth: 4,
            marker: {
              symbol: "circle",
              radius: 6
            },
            //color: "#ff502d",
            color: "#343f63",
            fillColor: "rgba(64, 143, 222, 0.6)"
            
          }
        ]
      };

      this.modalGraph = {
        chart: { type: "areaspline" },
        credits: {
          enabled: false
        },
        title: { text: "" },
        legend: { enabled: true },
        xAxis: {
          categories: catTT,
          tickmarkPlacement: "on",
          title: {
            enabled: false
          },
          labels: {
            style: {
              fontFamily: "'Circular', sans-serif",
              fontSize: "1.35em",
              color: "#343f63"
            }
          }
        },
        yAxis: {
          labels: {
            enabled: true,
            formatter() {
              return (
                "<span>" +
                this.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
                " GB</span>"
              );
            },
            style: {
              fontFamily: "'Circular', sans-serif",
              fontSize: "1.35em",
              color: "#343f63"
            }
          },
          title: {
            enabled: false
          }
        },
        tooltip: {
          formatter() {
            return `<strong>${this.point.y
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} GB</strong>`;
          }
        },
        /*
        series: [
          {
            name: "Company Usage",
            tooltip: {
              split: true
            },
            data:
              dataProm.length > 12
                ? dataProm.slice(dataProm.length - 12, dataProm.length)
                : dataProm,
            lineWidth: 3,
            marker: {
              symbol: "circle",
              radius: 5
            },
            color: "#ff502d",
            fillColor: "rgba(255, 93, 93, 0.5)"
          },
          {
            name: "Data Pool Size",
            tooltip: {
              split: true
            },
            data:
              dataUser.length > 12
                ? dataUser.slice(dataUser.length - 12, dataUser.length)
                : dataUser,
            lineWidth: 4,
            marker: {
              symbol: "circle",
              radius: 6
            },
            color: "#343f63",
            fillColor: "rgba(64, 143, 222, 0.6)"
          }
        ]
        */
       series: [
          {
            //name: "Company Usage",
            name: "Data Pool Size",
            tooltip: {
              split: true
            },
            /*
            data:
              dataProm.length > 12
                ? dataProm.slice(dataProm.length - 12, dataProm.length)
                : dataProm,
                */
            data:
              dataUser.length > 12
                ? dataUser.slice(dataUser.length - 12, dataUser.length)
                : dataUser,    
            lineWidth: 3,
            marker: {
              symbol: "circle",
              radius: 5
            },
              color: "#ff502d",
             //color: "#343f63",
             fillColor: "rgba(64, 143, 222, 0.6)"
             
          },
          {
            //name: "Data Pool Size",
            name: "Company Usage",
            tooltip: {
              split: true
            },
            /*
            data:
              dataUser.length > 12
                ? dataUser.slice(dataUser.length - 12, dataUser.length)
                : dataUser,
            */
            data:
              dataProm.length > 12
                ? dataProm.slice(dataProm.length - 12, dataProm.length)
                : dataProm,    
            lineWidth: 4,
            marker: {
              symbol: "circle",
              radius: 6
            },
            //color: "#ff502d",
            color: "#343f63",
            fillColor: "rgba(64, 143, 222, 0.6)"
            //fillColor: "rgba(255, 93, 93, 0.5)"
          }
        ]
      };
    }
  }
};
</script>
