var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-sm-12 px-0"},[_c('div',{staticClass:"accordion",attrs:{"role":"tablist"}},[_c('b-card',{staticClass:"mb-1 px-0 py-0",attrs:{"no-body":""}},[_c('b-card-header',{staticClass:"p-1",attrs:{"header-tag":"header","role":"tab"}},[_c('b-navbar-toggle',{staticClass:"title-card",attrs:{"target":"top-talk-time-users"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var expanded = ref.expanded;
return [_c('span',[_vm._v("Top Talk Time Users ")]),(expanded)?_c('i',{staticClass:"far fa-angle-up"}):_c('i',{staticClass:"far fa-angle-down"})]}}])})],1),_c('b-collapse',{attrs:{"id":"top-talk-time-users","accordion":"top-talk-time-users","role":"tabpanel"}},[_c('b-card-body',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-sm-12"},[_c('grid-loader',{staticClass:"expertel-loader",attrs:{"color":"#ff502d","loading":_vm.loading,"size":20,"sizeUnit":"px"}})],1)]),(_vm.data_completed === true)?_c('div',[_c('div',{staticClass:"accordion",attrs:{"role":"tablist"}},_vm._l((_vm.lines.slice(0, 5)),function(line,key){return _c('div',{key:key},[_c('table',{staticClass:"dashboard-table"},[(key == 0)?_c('thead',[_c('tr',[_c('th',[_vm._v("User")]),_c('th',{staticClass:"text-center d-none d-md-table-cell"},[_vm._v(" Carrier ")]),_c('th',{staticClass:"col-wb"}),_c('th',{staticClass:"text-center"},[_vm._v("Minutes")]),_c('th')])]):_vm._e(),_c('tbody',[_c('tr',[_c('td',[_vm._v(" "+_vm._s(line.name)+" "),_c('br'),_c('strong',[_vm._v(_vm._s(_vm._f("phoneNumber")(line.phone)))])]),_c('td',{staticClass:"text-center d-none d-md-table-cell"},[_c('img',{staticClass:"img-fluid carrier-img",attrs:{"src":("https://api.expertel.ca/dist/images/" + (line.carrier) + ".svg")}})]),_c('td',{staticClass:"col-wb"},[_c('b-progress',{staticClass:"w-75",attrs:{"value":line.relative}})],1),_c('td',{staticClass:"text-center slim-info-td"},[_vm._v(" "+_vm._s(_vm._f("numbers")(line.usage))+" ")]),_c('td',{staticClass:"text-right"},[_c('b-navbar-toggle',{attrs:{"target":("collapse-modal-ttalku-" + key)},on:{"click":function($event){return _vm.getTopData(line.phone, key)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var expanded = ref.expanded;
return [(expanded)?_c('i',{staticClass:"fas fa-times color-orange ml-3"}):_c('i',{staticClass:"fas fa-chart-bar color-orange ml-3"})]}}],null,true)})],1)])])]),_c('b-collapse',{staticClass:"top-modal",attrs:{"id":("collapse-modal-ttalku-" + key),"accordion":"ttalku-accordion","role":"tabpanel"}},[(line.collapsed)?_c('div',[_c('h2',{staticClass:"color-white"},[_vm._v(" "+_vm._s(line.name)+" Monthly Talk Usage ")]),_c('hr'),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-sm-12"},[_c('grid-loader',{staticClass:"expertel-loader",attrs:{"color":"#ff502d","loading":_vm.loading_modal,"size":20,"sizeUnit":"px"}})],1)]),(_vm.loading_modal == false)?_c('div',{staticClass:"row mt-4"},[_c('div',{staticClass:"col-12 col-sm-12"},[(_vm.monthly_data.data.length > 0 && _vm.login_data.client.is_trial)?_c('highcharts',{staticClass:"optimizerChart",attrs:{"options":_vm.stockOptions,"callback":_vm.resizeChart()}}):_vm._e(),(_vm.loading_modal == false && _vm.login_data.client.is_trial &&  _vm.monthly_data.data.length == 0)?_c('img',{staticStyle:{"max-width":"100%"},attrs:{"src":require("@/assets/free-trial.jpg"),"alt":"IMG"}}):_vm._e()],1)]):_vm._e()]):_vm._e()])],1)}),0),_c('p',{staticClass:"mt-3 mb-0 text-right"},[_c('a',{staticClass:"link mr-3",attrs:{"href":_vm.reportMeta.csv_url,"target":"_blank"}},[_c('strong',[_vm._v("Download CSV")])]),_c('button',{directives:[{name:"b-modal",rawName:"v-b-modal.modalTOTATIUS",modifiers:{"modalTOTATIUS":true}}],staticClass:"link"},[_c('strong',[_vm._v("View All")])])])]):_vm._e()])],1)],1)],1)]),_c('b-modal',{staticClass:"card",attrs:{"id":"modalTOTATIUS","size":"xl","centered":"","scrollable":""},scopedSlots:_vm._u([{key:"modal-header",fn:function(ref){
var close = ref.close;
return [_c('h3',{staticClass:"font-circular-book mx-auto"},[_vm._v("Top Talk Time Users")]),_c('b-button',{attrs:{"size":"sm","variant":"outline-expertel"},on:{"click":function($event){return close()}}},[_c('i',{staticClass:"fas fa-times    "})])]}},{key:"modal-footer",fn:function(ref){
var close = ref.close;
return [_c('div',{staticClass:"w-100"},[_c('button',{staticClass:"float-right button-expertel",attrs:{"aria-label":"Close","type":"button"},on:{"click":function($event){return close()}}},[_vm._v(" Close ")])])]}}])},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-sm-12"},[(_vm.data_completed === true)?_c('div',[_c('div',{staticClass:"accordion",attrs:{"role":"tablist"}},_vm._l((_vm.lines),function(line,key){return _c('div',{key:key},[_c('table',{staticClass:"dashboard-table"},[(key == 0)?_c('thead',[_c('tr',[_c('th',[_vm._v("User")]),_c('th',{staticClass:"text-center d-none d-md-table-cell"},[_vm._v(" Carrier ")]),_c('th',{staticClass:"col-wb"}),_c('th',{staticClass:"text-center"},[_vm._v("Minutes")]),_c('th')])]):_vm._e(),_c('tbody',[_c('tr',[_c('td',[_vm._v(" "+_vm._s(line.name)+" "),_c('br'),_c('strong',[_vm._v(_vm._s(_vm._f("phoneNumber")(line.phone)))])]),_c('td',{staticClass:"text-center d-none d-md-table-cell"},[_c('img',{staticClass:"img-fluid carrier-img",attrs:{"src":("https://api.expertel.ca/dist/images/" + (line.carrier) + ".svg")}})]),_c('td',{staticClass:"col-wb"},[_c('b-progress',{staticClass:"w-75",attrs:{"value":line.relative}})],1),_c('td',{staticClass:"text-center"},[_c('strong',[_vm._v(_vm._s(_vm._f("numbers")(line.usage)))])]),_c('td',{staticClass:"text-right"},[_c('b-navbar-toggle',{attrs:{"target":("collapse-ttalku-" + key)},on:{"click":function($event){return _vm.getTopData(line.phone, key)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var expanded = ref.expanded;
return [(expanded)?_c('i',{staticClass:"fas fa-times color-orange ml-3"}):_c('i',{staticClass:"fas fa-chart-bar color-orange ml-3"})]}}],null,true)})],1)])])]),_c('b-collapse',{staticClass:"top-modal",attrs:{"id":("collapse-ttalku-" + key),"accordion":"ttalku-accordion","role":"tabpanel"}},[(line.collapsed)?_c('div',[_c('h2',{staticClass:"color-white"},[_vm._v(" "+_vm._s(line.name)+" Monthly Talk Usage ")]),_c('hr'),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-sm-12"},[_c('grid-loader',{staticClass:"expertel-loader",attrs:{"color":"#ff502d","loading":_vm.loading_modal,"size":20,"sizeUnit":"px"}})],1)]),(_vm.loading_modal == false)?_c('div',{staticClass:"row mt-4"},[_c('div',{staticClass:"col-12 col-sm-12"},[(_vm.monthly_data.data.length > 0 && _vm.login_data.client.is_trial)?_c('highcharts',{staticClass:"optimizerChart",attrs:{"options":_vm.stockOptions,"callback":_vm.resizeChart()}}):_vm._e(),(_vm.loading_modal == false && _vm.login_data.client.is_trial &&  _vm.monthly_data.data.length == 0)?_c('img',{staticStyle:{"max-width":"100%"},attrs:{"src":require("@/assets/free-trial.jpg"),"alt":"IMG"}}):_vm._e()],1)]):_vm._e()]):_vm._e()])],1)}),0)]):_vm._e()])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }