<template>
  <div class="row">
    <div class="col-12 col-sm-12 px-0">
      <div class="accordion" role="tablist">
        <b-card no-body class="mb-1 px-0 py-0">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-navbar-toggle target="top-talk-time-users" class="title-card">
              <template #default="{ expanded }">
                <span>Top Talk Time Users </span>
                <i class="far fa-angle-up" v-if="expanded"></i>
                <i class="far fa-angle-down" v-else></i>
              </template>
            </b-navbar-toggle>
          </b-card-header>
          <b-collapse
            id="top-talk-time-users"
            accordion="top-talk-time-users"
            role="tabpanel"
          >
            <b-card-body>
              <div class="row">
                <div class="col-12 col-sm-12">
                  <grid-loader
                    class="expertel-loader"
                    color="#ff502d"
                    :loading="loading"
                    :size="20"
                    sizeUnit="px"
                  ></grid-loader>
                </div>
              </div>
              <div v-if="data_completed === true">
                <div class="accordion" role="tablist">
                  <div v-for="(line, key) in lines.slice(0, 5)" :key="key">
                    <table class="dashboard-table">
                      <thead v-if="key == 0">
                        <tr>
                          <th>User</th>
                          <th class="text-center d-none d-md-table-cell">
                            Carrier
                          </th>
                          <th class="col-wb"></th>
                          <th class="text-center">Minutes</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            {{ line.name }} <br /><strong>{{
                              line.phone | phoneNumber
                            }}</strong>
                          </td>
                          <td class="text-center d-none d-md-table-cell">
                            <img
                              :src="
                                `https://api.expertel.ca/dist/images/${line.carrier}.svg`
                              "
                              class="img-fluid carrier-img"
                            />
                          </td>
                          <td class="col-wb">
                            <b-progress
                              :value="line.relative"
                              class="w-75"
                            ></b-progress>
                          </td>
                          <td class="text-center slim-info-td">
                            {{ line.usage | numbers }}
                          </td>
                          <td class="text-right">
                            <b-navbar-toggle
                              :target="`collapse-modal-ttalku-${key}`"
                              @click="getTopData(line.phone, key)"
                            >
                              <template #default="{ expanded }">
                                <i
                                  v-if="expanded"
                                  class="fas fa-times color-orange ml-3"
                                ></i>
                                <i
                                  v-else
                                  class="fas fa-chart-bar color-orange ml-3"
                                ></i>
                              </template>
                            </b-navbar-toggle>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <b-collapse
                      :id="`collapse-modal-ttalku-${key}`"
                      class="top-modal"
                      accordion="ttalku-accordion"
                      role="tabpanel"
                    >
                      <div v-if="line.collapsed">
                        <h2 class="color-white">
                          {{ line.name }} Monthly Talk Usage
                        </h2>
                        <hr />
                        <div class="row">
                          <div class="col-12 col-sm-12">
                            <grid-loader
                              class="expertel-loader"
                              color="#ff502d"
                              :loading="loading_modal"
                              :size="20"
                              sizeUnit="px"
                            ></grid-loader>
                          </div>
                        </div>
                        <div class="row mt-4" v-if="loading_modal == false">
                          <div class="col-12 col-sm-12">
                            <highcharts
                              class="optimizerChart"
                              :options="stockOptions"
                              :callback="resizeChart()"
                              v-if="monthly_data.data.length > 0 && login_data.client.is_trial"
                            >
                            </highcharts>
                            <img src="@/assets/free-trial.jpg" alt="IMG" style="max-width: 100%;"
                              v-if="loading_modal == false && login_data.client.is_trial &&  monthly_data.data.length == 0"
                            />
                          </div>
                        </div>
                      </div>
                    </b-collapse>
                  </div>
                </div>
                <p class="mt-3 mb-0 text-right">
                  <a
                    :href="reportMeta.csv_url"
                    target="_blank"
                    class="link mr-3"
                    ><strong>Download CSV</strong></a
                  >
                  <button class="link" v-b-modal.modalTOTATIUS>
                    <strong>View All</strong>
                  </button>
                </p>
              </div>
            </b-card-body>
          </b-collapse>
        </b-card>
      </div>
    </div>
    <!-- [[ MODAL ]] -->
    <b-modal id="modalTOTATIUS" size="xl" centered scrollable class="card">
      <template #modal-header="{ close }">
        <h3 class="font-circular-book mx-auto">Top Talk Time Users</h3>
        <!-- Emulate built in modal header close button action -->
        <b-button size="sm" variant="outline-expertel" @click="close()">
          <i class="fas fa-times    "></i>
        </b-button>
      </template>
      <div class="row">
        <div class="col-12 col-sm-12">
          <div v-if="data_completed === true">
            <div class="accordion" role="tablist">
              <div v-for="(line, key) in lines" :key="key">
                <table class="dashboard-table">
                  <thead v-if="key == 0">
                    <tr>
                      <th>User</th>
                      <th class="text-center d-none d-md-table-cell">
                        Carrier
                      </th>
                      <th class="col-wb"></th>
                      <th class="text-center">Minutes</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        {{ line.name }} <br /><strong>{{
                          line.phone | phoneNumber
                        }}</strong>
                      </td>
                      <td class="text-center d-none d-md-table-cell">
                        <img
                          :src="
                            `https://api.expertel.ca/dist/images/${line.carrier}.svg`
                          "
                          class="img-fluid carrier-img"
                        />
                      </td>
                      <td class="col-wb">
                        <b-progress
                          :value="line.relative"
                          class="w-75"
                        ></b-progress>
                      </td>
                      <td class="text-center">
                        <strong>{{ line.usage | numbers }}</strong>
                      </td>
                      <td class="text-right">
                        <b-navbar-toggle
                          :target="`collapse-ttalku-${key}`"
                          @click="getTopData(line.phone, key)"
                        >
                          <template #default="{ expanded }">
                            <i
                              v-if="expanded"
                              class="fas fa-times color-orange ml-3"
                            ></i>
                            <i
                              v-else
                              class="fas fa-chart-bar color-orange ml-3"
                            ></i>
                          </template>
                        </b-navbar-toggle>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <b-collapse
                  :id="`collapse-ttalku-${key}`"
                  class="top-modal"
                  accordion="ttalku-accordion"
                  role="tabpanel"
                >
                  <div v-if="line.collapsed">
                    <h2 class="color-white">
                      {{ line.name }} Monthly Talk Usage
                    </h2>
                    <hr />
                    <div class="row">
                      <div class="col-12 col-sm-12">
                        <grid-loader
                          class="expertel-loader"
                          color="#ff502d"
                          :loading="loading_modal"
                          :size="20"
                          sizeUnit="px"
                        ></grid-loader>
                      </div>
                    </div>
                    <div class="row mt-4" v-if="loading_modal == false">
                      <div class="col-12 col-sm-12">
                        <highcharts
                          class="optimizerChart"
                          :options="stockOptions"
                          :callback="resizeChart()"
                          v-if="monthly_data.data.length > 0 && login_data.client.is_trial"
                        >
                        </highcharts>
                        <img src="@/assets/free-trial.jpg" alt="IMG" style="max-width: 100%;"
                          v-if="loading_modal == false && login_data.client.is_trial &&  monthly_data.data.length == 0"
                        />
                      </div>
                    </div>
                  </div>
                </b-collapse>
              </div>
            </div>
          </div>
        </div>
      </div>
      <template #modal-footer="{ close }">
        <div class="w-100">
          <button
            aria-label="Close"
            type="button"
            class="float-right button-expertel"
            @click="close()"
          >
            Close
          </button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: ["login_data", "id_report"],
  data() {
    return {
      lines: [],
      monthly_data: [],
      stockOptions: {},
      data_completed: false,
      loading: true,
      loading_modal: true,
      reportMeta: [],
      dataUser: []
    };
  },
  watch: {
    id_report: function(newVal) {
      this.getData(newVal);
    }
  },
  created() {
    if (this.id_report !== "") {
      this.getData(this.id_report);
    }
  },
  methods: {
    getData(id_report) {
      const self = this;
      this.axios({
        method: "get",
        url: `https://api.expertel.ca/api/v1/client/me/reports/${id_report}/data?type=top_voice`,
        responseType: "json",
        headers: {
          Authorization: "Bearer " + self.login_data.token
        }
      })
        .then(function(response) {
          self.reportMeta = response.data.meta;
          response.data.data.forEach(data => {
            self.lines.push({
              phone: data.phone,
              name: data.name,
              carrier: data.carrier,
              usage: data.usage,
              relative: data.relative,
              extra_details_url: data.extra_details_url,
              collapsed: false
            });
          });
          self.data_completed = true;
          self.loading = false;
          //sort((a, b) => a.relative - b.relative)
        })
        .catch(error => (console.log(error), alert(error)));
    },
    async getTopData(phone, key) {
      const self = this;

      if (this.lines[key].collapsed == false) {
        this.lines.forEach(data => {
          if (data.collapsed == true) {
            data.collapsed = false;
          }
        });
        this.lines[key].collapsed = !this.lines[key].collapsed;
        this.loading_modal = true;
        await this.axios({
          method: "get",
          url: `https://api.expertel.ca/api/v1/client/me/reports/${this.id_report}/data?type=individual_usage&phone=${phone}&method=voice`,
          responseType: "json",
          headers: {
            Authorization: "Bearer " + self.login_data.token
          }
        })
          .then(function(response) {
            self.monthly_data = response.data;
            self.loading_modal = false;
          })
          .catch(error => (console.log(error), alert(error)));

        self.dataUser = [];
        let catTT = [],
          dataProm = [];
        this.monthly_data.data.forEach(data => {
          catTT.push(data.month);
          self.dataUser.push(data.usage);
          dataProm.push(data.average_usage);
        });

        catTT =
          catTT.length > 6
            ? catTT.slice(catTT.length - 6, catTT.length)
            : catTT;
        self.dataUser =
          self.dataUser.length > 6
            ? self.dataUser.slice(
                self.dataUser.length - 6,
                self.dataUser.length
              )
            : self.dataUser;
        dataProm =
          dataProm.length > 6
            ? dataProm.slice(dataProm.length - 6, dataProm.length)
            : dataProm;

        this.stockOptions = {
          responsive: {
            rules: [
              {
                condition: {
                  maxWidth: 300
                },
                chartOptions: {
                  xAxis: {
                    labels: {
                      style: {
                        fontSize: "1em"
                      }
                    }
                  },
                  yAxis: {
                    labels: {
                      style: {
                        fontSize: "1em"
                      }
                    }
                  }
                }
              }
            ]
          },
          chart: {
            type: "areaspline",
            backgroundColor: "transparent",
            height: "250px"
          },
          credits: {
            enabled: false
          },
          title: { text: "" },
          legend: {
            layout: "horizontal",
            align: "center",
            verticalAlign: "bottom",
            labelFormatter: function() {
              return '<span style="color: #FFFFFF">' + this.name + "</span>";
            }
          },
          xAxis: {
            categories: catTT,
            tickmarkPlacement: "on",
            title: {
              enabled: true
            },
            labels: {
              style: {
                fontFamily: "'Circular', sans-serif",
                fontSize: "1.35em",
                color: "#fff"
              }
            }
          },
          yAxis: {
            lineColor: "#FFF",
            lineWidth: 1,
            labels: {
              formatter() {
                return (
                  "<span>" +
                  this.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
                  " Mins</span>"
                );
              },
              style: {
                fontFamily: "'Circular', sans-serif",
                fontSize: "1.35em",
                color: "#fff"
              }
            },
            title: {
              enabled: false
            }
          },
          tooltip: {
            formatter() {
              return (
                "<strong>" +
                this.y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
                " Mins</strong>"
              );
            },
            split: false
          },
          series: [
            {
              name: "Company Average",
              data: dataProm,
              lineWidth: 4,
              marker: {
                symbol: "circle",
                radius: 0
              },
              color: "#ff502d",
              fillColor: "transparent"
            },
            {
              name: "Usage",
              data: self.dataUser,
              lineWidth: 4,
              marker: {
                symbol: "circle",
                radius: 0
              },
              color: "#7d77f3",
              fillColor: "transparent"
            }
          ]
        };
      } else {
        this.lines[key].collapsed = !this.lines[key].collapsed;
      }
    },
    resizeChart() {
      if (this.stockOptions.series[1].data !== undefined) {
        this.stockOptions.series[1].data = this.dataUser;
      }
    }
  }
};
</script>
