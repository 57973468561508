<template>
  <div class="px-1 px-md-4">
    <div class="row mb-2">
      <div class="col-12 col-sm-12">
        <h2 class="main-title">Overview</h2>
      </div>
    </div>
    <div class="row my-3 my-lg-0">
      <div class="col-12 col-sm-12 col-lg-4">
        <optimizer :login_data="login_data" :id_report="id_report" />
      </div>
      <div class="col-12 col-sm-12 col-lg-4 mt-4 mt-lg-0">
        <auditor :login_data="login_data" :id_report="id_report" />
      </div>
      <div class="col-12 col-sm-12 col-lg-4 mt-4 mt-lg-0">
        <market-watch :login_data="login_data" :id_report="id_report" />
      </div>
      <div class="col-12 col-sm-12 col-lg-6 mt-4" v-if="id_report !== ''">
        <billing-overview :login_data="login_data" :id_report="id_report" />
      </div>
      <div class="col-12 col-sm-12 col-lg-6 mt-4" v-if="id_report !== ''">
        <line-count-overview :login_data="login_data" :id_report="id_report" />
      </div>
      <div class="col-12 col-sm-12 col-lg-6 mt-4" v-if="id_report !== ''">
        <total-spend :login_data="login_data" :id_report="id_report" />
      </div>
      <div class="col-12 col-sm-12 col-lg-6 mt-4" v-if="id_report !== ''">
        <data-usage :login_data="login_data" :id_report="id_report" />
      </div>
      <div class="col-12 col-sm-12 mt-4" v-if="id_report !== ''">
        <current-data-usage :login_data="login_data" :id_report="id_report" />
      </div>
      <div class="col-12 col-sm-12 col-lg-6 mt-4" v-if="id_report !== ''">
        <active-lines :login_data="login_data" :id_report="id_report" />
      </div>
      <div class="col-12 col-sm-12 col-lg-6 mt-4" v-if="id_report !== ''">
        <average-cost-per-active-line
          :login_data="login_data"
          :id_report="id_report"
        />
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-12 col-sm-12">
        <h2 class="mt-3 mt-40px">User Details</h2>
      </div>
    </div>
    <div class="row my-3 mt-lg-2" v-if="movil == false">
      <div class="col-12 col-sm-12" v-if="id_report !== ''">
        <AdditionalChargesSummary
          :login_data="login_data"
          :id_report="id_report"
        />
      </div>
      <div class="col-12 col-sm-12 col-lg-6 px-0">
        <div class="col-12 col-sm-12 mt-4" v-if="id_report !== ''">
          <UnusedLine :login_data="login_data" :id_report="id_report" />
        </div>
        <div class="col-12 col-sm-12 mt-4" v-if="id_report !== ''">
          <TopSpendingUsers :login_data="login_data" :id_report="id_report" />
        </div>
        <div class="col-12 col-sm-12 mt-4" v-if="id_report !== ''">
          <TopTalkTimeUsers :login_data="login_data" :id_report="id_report" />
        </div>
        <div class="col-12 col-sm-12 mt-4" v-if="id_report !== ''">
          <TopRoamingUsers
            :login_data="login_data"
            :id_report="id_report"
            :all_modals="all_modals"
          />
        </div>
        <div class="col-12 col-sm-12 mt-4" v-if="id_report !== ''">
          <AdditionalCharges :login_data="login_data" :id_report="id_report" />
        </div>
      </div>
      <div class="col-12 col-sm-12 col-lg-6 px-0">
        <div class="col-12 col-sm-12 mt-4" v-if="id_report !== ''">
          <SuspendedLines :login_data="login_data" :id_report="id_report" />
        </div>
        <div class="col-12 col-sm-12 mt-4" v-if="id_report !== ''">
          <TopDataUsers :login_data="login_data" :id_report="id_report" />
        </div>
        <div class="col-12 col-sm-12 mt-4" v-if="id_report !== ''">
          <TopMessagengUsers :login_data="login_data" :id_report="id_report" />
        </div>
        <div class="col-12 col-sm-12 mt-4" v-if="id_report !== ''">
          <CreditsApplied :login_data="login_data" :id_report="id_report" />
        </div>
        <div class="col-12 col-sm-12 mt-4" v-if="id_report !== ''">
          <OtherCharges :login_data="login_data" :id_report="id_report" />
        </div>
      </div>
    </div>
    <div class="row my-3 mt-lg-2" v-else>
      <div class="col-12 col-sm-12" v-if="id_report !== ''">
        <AdditionalChargesSummary
          :login_data="login_data"
          :id_report="id_report"
        />
      </div>
      <div class="col-12 col-sm-12 px-0">
        <div class="col-12 col-sm-12 mt-4" v-if="id_report !== ''">
          <UnusedLine :login_data="login_data" :id_report="id_report" />
        </div>
        <div class="col-12 col-sm-12 mt-4" v-if="id_report !== ''">
          <SuspendedLines :login_data="login_data" :id_report="id_report" />
        </div>
        <div class="col-12 col-sm-12 mt-4" v-if="id_report !== ''">
          <TopSpendingUsers :login_data="login_data" :id_report="id_report" />
        </div>
        <div class="col-12 col-sm-12 mt-4" v-if="id_report !== ''">
          <TopDataUsers :login_data="login_data" :id_report="id_report" />
        </div>
        <div class="col-12 col-sm-12 mt-4" v-if="id_report !== ''">
          <TopTalkTimeUsers :login_data="login_data" :id_report="id_report" />
        </div>
        <div class="col-12 col-sm-12 mt-4" v-if="id_report !== ''">
          <TopMessagengUsers :login_data="login_data" :id_report="id_report" />
        </div>
        <div class="col-12 col-sm-12 mt-4" v-if="id_report !== ''">
          <TopRoamingUsers
            :login_data="login_data"
            :id_report="id_report"
            :all_modals="all_modals"
          />
        </div>
        <div class="col-12 col-sm-12 mt-4" v-if="id_report !== ''">
          <CreditsApplied :login_data="login_data" :id_report="id_report" />
        </div>
        <div class="col-12 col-sm-12 mt-4" v-if="id_report !== ''">
          <AdditionalCharges :login_data="login_data" :id_report="id_report" />
        </div>
        <div class="col-12 col-sm-12 mt-4" v-if="id_report !== ''">
          <OtherCharges :login_data="login_data" :id_report="id_report" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//OVERVIEW COMPONENTS
import Optimizer from "@/components/overview/Optimizer.vue";
import Auditor from "@/components/overview/Auditor.vue";
import MarketWatch from "@/components/overview/MarketWatch.vue";
import BillingOverview from "@/components/overview/BillingOverview.vue";
import LineCountOverview from "@/components/overview/LineCountOverview.vue";
import TotalSpend from "@/components/overview/TotalSpend.vue";
import DataUsage from "@/components/overview/DataUsage.vue";
import CurrentDataUsage from "@/components/overview/CurrentDataUsage.vue";
import ActiveLines from "@/components/overview/ActiveLines.vue";
import AverageCostPerActiveLine from "@/components/overview/AverageCostPerActiveLine.vue";

//USER DETAIL COMPONENTS
import AdditionalCharges from "@/components/userdetails/AdditionalCharges.vue";
import AdditionalChargesSummary from "@/components/userdetails/AdditionalChargesSummary.vue";
import CreditsApplied from "@/components/userdetails/CreditsApplied.vue";
import OtherCharges from "@/components/userdetails/OtherCharges.vue";
import SuspendedLines from "@/components/userdetails/SuspendedLines.vue";
import TopDataUsers from "@/components/userdetails/TopDataUsers.vue";
import TopMessagengUsers from "@/components/userdetails/TopMessagengUsers.vue";
import TopRoamingUsers from "@/components/userdetails/TopRoamingUsers.vue";
import TopSpendingUsers from "@/components/userdetails/TopSpendingUsers.vue";
import TopTalkTimeUsers from "@/components/userdetails/TopTalkTimeUsers.vue";
import UnusedLine from "@/components/userdetails/UnusedLine.vue";

export default {
  props: ["login_data", "id_report"],
  components: {
    Optimizer,
    Auditor,
    MarketWatch,
    BillingOverview,
    LineCountOverview,
    TotalSpend,
    DataUsage,
    CurrentDataUsage,
    ActiveLines,
    AverageCostPerActiveLine,
    AdditionalCharges,
    AdditionalChargesSummary,
    CreditsApplied,
    OtherCharges,
    SuspendedLines,
    TopDataUsers,
    TopMessagengUsers,
    TopRoamingUsers,
    TopSpendingUsers,
    TopTalkTimeUsers,
    UnusedLine
  },
  data() {
    return {
      optimizer_data: [],
      billing_details: [],
      all_modals: [],
      line_count: [],
      complete_bo: false,
      complete_lc: false,
      movil: false
    };
  },
  created() {

    //It verify is the user es new 
    if(this.login_data.is_new_client)
    {
      this.$router.push("/connect-carrier");
      return false;
    }

    if (this.login_data !== undefined || this.login_data.success === false) {

      if (!this.login_data.success) {
        this.$router.push("/");
      }

      if (window.outerWidth < 767) {
        this.movil = true;
      }
    } else {
      this.$router.push("/");
    }
  }
};
</script>

<style lang="scss">
    
    #item_my_account,
    .sidebar-primary {
        width: 0px;
        display: block !important;
    }
    
</style>
