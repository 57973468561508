<template>
  <div class="card overflow-visible">
    <div class="row">
      <div class="col-12 col-sm-12">
        <h2>Total Spend</h2>
      </div>
      <div class="col-12 col-sm-12"></div>
    </div>
    <div class="row">
      <div class="col-12 col-sm-12">
        <grid-loader
          class="expertel-loader"
          color="#ff502d"
          :loading="loading"
          :size="20"
          sizeUnit="px"
        ></grid-loader>
      </div>
    </div>
    <div class="row mt-4" v-if="loading == false">
      <div class="col-12 col-sm-12" id="holderPreviewChart">
        <highcharts class="optimizerChart" :options="stockOptions"></highcharts>
        <div
          id="holderChartPreview"
          v-show="show_pop === true"
          class="grafPop"
          :style="{
            position: 'absolute',
            top: `calc(${posY}px + 35px)`,
            left: `${posX}px`
          }"
        >
          <div
            class="triangle"
            :style="{
              position: 'absolute',
              left: `${posTriangle}px`
            }"
          >
            <i class="fas fa-caret-up"></i>
          </div>
          <div class="row">
            <div class="col-12 col-sm-12">

              <grid-loader
                class="expertel-loader"
                color="#ff502d"
                :loading="loading_modal"
                :size="20"
                sizeUnit="px"
                v-if="!is_trial"
              ></grid-loader>
              <div v-if="is_trial" class="text-center">
                <b-button
                  size="sm"
                  variant="outline-expertel close"
                  @click="close_grap()"
                >
                  <i class="fas fa-times    "></i>
                </b-button>
                <img src="@/assets/free-trial.jpg" alt="IMG" style="max-width: 80%;"
                />
              </div>
            </div>
          </div>
          <div class="row pop-graf" v-if="loading_modal == false">
            <div class="col-12 col-sm-12">
              <b-button
                size="sm"
                variant="outline-expertel close"
                @click="close_grap()"
              >
                <i class="fas fa-times    "></i>
              </b-button>
              <h2>{{ pop_title }} </h2>
              <highcharts
                class="optimizerChart"
                :options="popPie"
                :callback="resizeChart()"
              ></highcharts>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-24px">
      <div class="col-12 col-sm-12 text-right">
        <p class="mb-0">
          <button class="link" v-b-modal.TTSpend>
            <strong>Show more</strong>
          </button>
        </p>
      </div>
    </div>

    <!-- [[ MODAL ]] -->
    <b-modal id="TTSpend" size="xl" centered>
      <template #modal-header="{ close }">
        <h3 class="font-circular-book mx-auto">Total Spend</h3>
        <!-- Emulate built in modal header close button action -->
        <b-button size="sm" variant="outline-expertel" @click="close()">
          <i class="fas fa-times    "></i>
        </b-button>
      </template>
      <div class="row">
        <div class="col-12 col-sm-12">
          <highcharts class="optimizerChart" :options="modalGraph"></highcharts>
          <div
            id="holderChart"
            v-show="show_pop_modal === true"
            class="grafPop"
            :style="{
              position: 'absolute',
              top: `calc(${posY}px + 35px)`,
              left: `${posX}px`
            }"
          >
            <div
              class="triangle"
              :style="{
                position: 'absolute',
                left: `${posTriangle}px`
              }"
            >
              <i class="fas fa-caret-up"></i>
            </div>
            <div class="row">
              <div class="col-12 col-sm-12">
                <grid-loader
                  class="expertel-loader"
                  color="#ff502d"
                  :loading="loading_modal"
                  :size="20"
                  sizeUnit="px"
                  v-if="!is_trial"
                ></grid-loader>
                <div v-if="is_trial" class="text-center">
                  <b-button
                    size="sm"
                    variant="outline-expertel close"
                    @click="close_grap_modal()"
                  >
                    <i class="fas fa-times    "></i>
                  </b-button>
                  <img src="@/assets/free-trial.jpg" alt="IMG" style="max-width: 80%;"
                  />
                </div>
              </div>
            </div>
            <div class="row pop-graf" v-if="loading_modal == false">
              <div class="col-12 col-sm-12">
                <b-button
                  size="sm"
                  variant="outline-expertel close"
                  @click="close_grap_modal()"
                >
                  <i class="fas fa-times    "></i>
                </b-button>
                <h2>{{ pop_title }}</h2>
                <highcharts
                  class="optimizerChart"
                  :options="popPie"
                  :callback="resizeChart()"
                ></highcharts>
              </div>
            </div>
          </div>
        </div>
      </div>
      <template #modal-footer="{ close }">
        <div class="w-100">
          <button
            aria-label="Close"
            type="button"
            class="float-right button-expertel"
            @click="close()"
          >
            Close
          </button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: ["id_report", "login_data"],
  data() {
    //var self = this;
    return {
      loading: true,
      show_pop: false,
      show_pop_modal: false,
      pop_title: "",
      posX: 0,
      posY: 0,
      posTriangle: 0,
      totalSpend: [],
      modalGraph: {},
      stockOptions: {},
      popPie: {},
      loading_modal: true,
      monthly_data: [],
      is_trial: false,  
      lastMonths: [], //this variable contains the last 12 months
      
    };
  },
  created() {
    if (this.id_report !== "") {
      this.getData(this.id_report);
    }
  },
  watch: {
    id_report: function(newVal) {
      this.getData(newVal);
    }
  },
  methods: {
    close_grap() {
      this.show_pop = false;
    },
    close_grap_modal() {
      this.show_pop_modal = false;
    },
    async getData(id_report) {
      const self = this;
      self.is_trial = self.login_data.client.is_trial
      await this.axios({
        method: "get",
        url: `https://api.expertel.ca/api/v1/client/me/reports/${id_report}/data?type=monthly_totals`,
        responseType: "json",
        headers: {
          Authorization: "Bearer " + self.login_data.token
        }
      })
        .then(function(response) {
          self.totalSpend = response.data;
          self.loading = false;
        })
        .catch(error => (console.log(error), alert(error)));

      let ttSpend = [], catTT = [];
      this.totalSpend.data.forEach(spendTT => {
        ttSpend.push([
          spendTT.cost_factor,
          parseFloat(spendTT.amount.toFixed(2))
        ]);
        catTT.push([spendTT.month]); //Save the name of the month
       
      });

      //console.log("---------------")
      //console.log(this.totalSpend)
      //console.log("---------------")

      //================================================
      //It saved the last 12 months
      //================================================
      let arrlastMonths = [];
      if(this.totalSpend.data.length > 12){
        arrlastMonths = this.totalSpend.data.slice(this.totalSpend.data.length - 12, this.totalSpend.data.length)
      }else{
        arrlastMonths = this.totalSpend.data
      }

      let map = []
      arrlastMonths.forEach(item => {
        map.push({'month':item.month, 'url':item.extra_details_url})
      })
      //this.lastMonths = map
      self.lastMonths = map

      //console.log(map)


      this.stockOptions = {
        chart: { type: "areaspline" },
        credits: {
          enabled: false
        },
        title: { text: "" },
        legend: { enabled: false },
        tooltip: {
          positioner: function(width, height, point) {
            var tooltipX = point.plotX + 40;
            var tooltipY = point.plotY - 55;
            return {
              x: tooltipX,
              y: tooltipY
            };
          },
          formatter() {
            return (
              "<span>$" +
              this.y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
              "</span>"
            );
          }
        },
        xAxis: {
          categories: catTT.slice(catTT.length - 6, catTT.length),
          tickmarkPlacement: "on",
          title: {
            enabled: false
          },
          labels: {
            y: 30,
            style: {
              fontFamily: "'Circular Book', sans-serif",
              fontSize: "1.35em",
              color: "#343f63"
            }
          }
        },
        yAxis: {
          lineWidth: 1,
          labels: {
            enabled: true,
            format: "${value}",
            formatter() {
              return (
                "<span>$" +
                this.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
                "</span>"
              );
            },
            style: {
              fontFamily: "'Circular Book', sans-serif",
              fontSize: "1.25em",
              color: "#343f63"
            },
            maxPadding: 0
          },
          title: {
            enabled: false
          }
        },
        plotOptions: {
          series: {
            allowPointSelect: true,
            point: {
              events: {
                click: function() {
                  let category = this.category;
                  let filteredMonth = self.lastMonths.filter(function (menu) {
                      return menu.month == category;
                  });

                  let url = "";
                  if(filteredMonth){
                    url = filteredMonth[0].url
                    url = url.replace("iq.expertel.ca", "api.expertel.ca");
                  }
                  self.popGraf(this.plotX, this.plotY, url);
                }
              }
            },
            cursor: "pointer"
          }
        },
        series: [
          {
            tooltip: {
              pointFormat: "<strong>${point.y}</strong>",
              split: false
            },
            data: ttSpend.slice(ttSpend.length - 6, ttSpend.length),
            lineWidth: 3,
            marker: {
              radius: 5
            },
            color: "#ff502d",
            fillColor: "rgba(255, 93, 93, 0.5)"
          }
        ]
      };

      this.modalGraph = {
        chart: { type: "areaspline" },
        credits: {
          enabled: false
        },
        title: { text: "" },
        legend: { enabled: false },
        tooltip: {
          positioner: function(width, height, point) {
            var tooltipX = point.plotX + 40;
            var tooltipY = point.plotY - 55;
            return {
              x: tooltipX,
              y: tooltipY
            };
          },
          formatter() {
            return (
              "<span>$" +
              this.y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
              "</span>"
            );
          }
        },
        xAxis: {
          categories: 
            catTT.length > 12
                  ? catTT.slice(catTT.length - 12, catTT.length)
                  : catTT,
          
          tickmarkPlacement: "on",
          title: {
            enabled: false
          },
          labels: {
            style: {
              fontFamily: "'Circular', sans-serif",
              fontSize: "1.35em",
              color: "#343f63"
            }
          }
        },
        yAxis: {
          labels: {
            enabled: true,
            formatter() {
              return (
                "<span>$" +
                this.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
                "</span>"
              );
            },
            style: {
              fontFamily: "'Circular', sans-serif",
              fontSize: "1.35em",
              color: "#343f63"
            },
            maxPadding: 0
          },
          title: {
            enabled: false
          }
        },
        series: [
          {
            tooltip: {
              pointFormat: "<strong>${point.y}</strong>",
              split: false
            },
            //data: ttSpend,
            //data: ttSpend.slice(ttSpend.length - 12, ttSpend.length),
            data:
              ttSpend.length > 12
                ? ttSpend.slice(ttSpend.length - 12, ttSpend.length)
                : ttSpend,
            lineWidth: 3,
            marker: {
              radius: 5
            },
            color: "#ff502d",
            fillColor: "rgba(255, 93, 93, 0.5)"
          }
        ],
        plotOptions: {
          series: {
            allowPointSelect: true,
            point: {
              events: {
                click: function() {
                  let category = this.category;
                  let filteredMonth = self.lastMonths.filter(function (menu) {
                      return menu.month == category;
                  });

                  let url = "";
                  if(filteredMonth){
                    url = filteredMonth[0].url
                    url = url.replace("iq.expertel.ca", "api.expertel.ca");
                  }
                  self.popGraf_modal(this.plotX, this.plotY, url);
                }
              }
            },
            cursor: "pointer"
          }
        }
      };
    },
    async popGraf(x, y, details_url) {
      this.show_pop = true;
      this.posX = x;
      this.posY = y;

      this.posX = x;
      this.posY = y;
      this.posTriangle = x;

      //console.log("---------------")
      //console.log(details_url)
      //console.log("---------------")
     

      let panelContainer = document.getElementById("holderPreviewChart");
      panelContainer = panelContainer.clientWidth;

      let contentChart = document.getElementById("holderChartPreview");
      let wChart =
        contentChart.clientWidth == 0 ? 580 : contentChart.clientWidth;
      let haft = Math.round(panelContainer / 2);
      let pos = panelContainer - this.posX;

      var mediaqueryList = window.matchMedia("(max-width: 990px)");
      if (mediaqueryList.matches) {
        this.posX = 0;
        this.posTriangle = x + 80;
      } else {
        if (pos < haft) {
          this.posX = this.posX - wChart + 150;
          this.posTriangle = wChart - 63;
        } else {
          this.posTriangle = 88;
        }
      }

      if(!details_url){
        //this.loading_modal = false;
        return false;
      }

      const self = this;
      this.loading_modal = true;
      await this.axios({
        method: "get",
        //url: `https://api.expertel.ca/api/v1/client/me/reports/${this.id_report}/data?type=cost_factors`,
        //url: 'https://api.expertel.ca/api/v1/client/me/reports/80612/data?type=cost_factors&small=1',
        url: details_url,
        responseType: "json",
        headers: {
          Authorization: "Bearer " + self.login_data.token
        }
      })
        .then(function(response) {
          self.monthly_data = response.data;
          self.loading_modal = false;
          self.pop_title = response.data.meta.title;
        })
        .catch(error => (console.log(error), alert(error)));

      let dataUser = [];
      let total_amount = 0;

      this.monthly_data.data.forEach(data => {
        total_amount += data.amount;
        dataUser.push({
          name: data.cost_factor,
          y: data.amount
        });
      });

      this.data_user = dataUser;

      this.popPie = {
        responsive: {
          rules: [
            {
              condition: {
                maxWidth: 300
              },
              chartOptions: {
                chart: {
                  height: "300px"
                },
                legend: {
                  align: "center",
                  verticalAlign: "bottom",
                  layout: "horizontal"
                }
              }
            }
          ]
        },
        chart: {
          type: "pie",
          backgroundColor: "transparent",
          height: "200px"
        },
        legend: {
          layout: "vertical",
          align: "right",
          verticalAlign: "middle",
          itemStyle: {
            color: "#fff",
            fontWeight: "normal",
            fontFamily: `"Circular Book", sans-serif`
          },
          labelFormatter: function() {
            return (
              '<span style="color: #FFFFFF">' +
              this.name +
              " / $" +
              this.y
                .toFixed(2)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
              " / " +
              ((this.y * 100) / total_amount).toFixed(2) +
              "%</span>"
            );
          }
        },
        colors: [
          "#ff502d",
          "#67F44A",
          "#7E79EF",
          "#4CEAF2",
          "#BAC61E",
          "#D300FF"
        ],
        title: {
          text: ""
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: "pointer",
            borderColor: null,
            dataLabels: {
              enabled: false
            },
            showInLegend: true
          }
        },
        series: [
          {
            minPointSize: 10,
            innerSize: "70%",
            zMin: 0,
            name: "",
            data: dataUser
          }
        ]
      };
    },
    async popGraf_modal(x, y, details_url) {
      this.show_pop_modal = true;

      this.posX = x;
      this.posY = y;
      this.posTriangle = x;

      let panelContainer = document.getElementById(
        "TTSpend___BV_modal_content_"
      );
      panelContainer = panelContainer.clientWidth;

      let contentChart = document.getElementById("holderChart");
      let wChart =
        contentChart.clientWidth == 0 ? 580 : contentChart.clientWidth;
      let haft = Math.round(panelContainer / 2);
      let pos = panelContainer - this.posX;

      var mediaqueryList = window.matchMedia("(max-width: 990px)");
      if (mediaqueryList.matches) {
        this.posX = 0;
        this.posTriangle = x + 90;
      } else {
        if (pos < haft) {
          this.posX = this.posX - wChart + 150;
          this.posTriangle = wChart - 63;
        } else {
          this.posTriangle = 88;
        }
      }

      if(!details_url){
        //this.loading_modal = false;
        return false;
      }

      const self = this;
      this.loading_modal = true;
      await this.axios({
        method: "get",
        //url: `https://api.expertel.ca/api/v1/client/me/reports/${this.id_report}/data?type=cost_factors`,
        url: details_url,
        responseType: "json",
        headers: {
          Authorization: "Bearer " + self.login_data.token
        }
      })
        .then(function(response) {
          self.monthly_data = response.data;
          self.loading_modal = false;
          self.pop_title = response.data.meta.title;
        })
        .catch(error => (console.log(error), alert(error)));

      let dataUser = [];
      let total_amount = 0;

      this.monthly_data.data.forEach(data => {
        total_amount += data.amount;
        dataUser.push({
          name: data.cost_factor,
          y: data.amount
        });
      });

      this.data_user = dataUser;

      this.popPie = {
        chart: {
          type: "pie",
          backgroundColor: "transparent",
          height: "200px"
        },
        legend: {
          layout: "vertical",
          align: "right",
          verticalAlign: "middle",
          itemStyle: {
            color: "#fff",
            fontWeight: "normal",
            fontFamily: `"Circular Book", sans-serif`
          },
          labelFormatter: function() {
            return (
              '<span style="color: #FFFFFF">' +
              this.name +
              " / $" +
              this.y
                .toFixed(2)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
              " / " +
              ((this.y * 100) / total_amount).toFixed(2) +
              "%</span>"
            );
          }
        },
        colors: [
          "#ff502d",
          "#67F44A",
          "#7E79EF",
          "#4CEAF2",
          "#BAC61E",
          "#D300FF"
        ],
        title: {
          text: ""
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: "pointer",
            borderColor: null,
            dataLabels: {
              enabled: false
            },
            showInLegend: true
          }
        },
        series: [
          {
            minPointSize: 10,
            innerSize: "70%",
            zMin: 0,
            name: "",
            data: dataUser
          }
        ],
        responsive: {
          rules: [
            {
              condition: {
                maxWidth: 300
              },
              chartOptions: {
                chart: {
                  height: "300px"
                },
                legend: {
                  align: "center",
                  verticalAlign: "bottom",
                  layout: "horizontal"
                }
              }
            }
          ]
        }
      };
    },
    resizeChart() {
      if (this.popPie.series.data !== undefined) {
        this.popPie.series.data = this.dataUser;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.marketWatchChart {
  width: 100%;
  margin: 0 auto;
}
.pop-graf h2 {
  color: white;
  border-bottom: 1px solid white;
  padding: 15px 0;
}
</style>

<style lang="scss" scoped>
.optimizerChart {
  overflow: visible !important;
  .highcharts-container {
    overflow: visible !important;
    .highcharts-root {
      overflow: visible !important;
    }
  }

  .holderChart {
    position: relative;
  }
}
</style>
