<template>
  <div
    class="card bg-white"
    :class="{ 'justify-content-center': loading, '': loading == false }"
  >
    <div class="row" v-if="loading == false">
      <div class="col-12 col-sm-12">
        <div class="title-button-blue">

           <!-- No traial account -->
          <router-link to="/auditor" v-if="!this.is_trial">
            Auditor
          </router-link>
          <!-- Trail Account -->
          <a
            href="#"
            @click.prevent="showMkwModal()"
            
            v-if="this.is_trial"
          >
            Auditor
          </a>

          <span>
            <i id="auditor-info" class="fal fa-info-circle openTooltip"></i>
            <b-tooltip target="auditor-info" placement="right">
              Auditor detects and displays billing anomalies and
              inconsistencies, some of which may have been billed in error.
            </b-tooltip>
          </span>
        </div>
      </div>
    </div>
    <div class="row" v-if="loading == true">
      <div class="col-12 col-sm-12">
        <grid-loader
          class="expertel-loader"
          color="#ff502d"
          :loading="loading"
          :size="20"
          sizeUnit="px"
        ></grid-loader>
      </div>
    </div>
    <div class="row py-3" v-if="loading == false">
      <div class="col-12 col-sm-12">
        <p class="font-16 line-height-20" v-if="number_charges">
          {{ number_charges }} Charges with a total of
          <strong>{{ total_amount | currencyDolar }}</strong> were incorrectly
          billed by the carrier.
        </p>
        <div class="font-16 line-height-20 auditor-good-news" v-else>
          <img src="@/assets/img/auditor-good-news.svg" alt="Good News" />
          <h3>Good News!</h3>
          <p>There are not billing errors to report</p>
        </div>
      </div>
    </div>

    <div class="row py-3" v-if="loading == false && number_charges">
      <div class="col-12 col-sm-12">
        <div
          v-for="(charge, index) in auditor_charges.slice(0, 3)"
          :key="index"
        >
          <b-alert show variant="expertel">
            <p>
              <span class="description">
                {{ charge.description }}
              </span>
              <strong class="cost">
                {{ charge.amount }}
              </strong>
            </p>
          </b-alert>
        </div>
        <p class="text-center" v-if="number_charges">
          <router-link to="/auditor" class="link color-blue"
            >View all charges</router-link
          >
        </p>
      </div>
    </div>
    <div class="row py-3" v-if="loading == false">
      <div class="col-12 col-sm-12">

        <!-- No Trial Account -->
        <router-link
          to="/auditor"
          class="button-expertel btn-h48 d-flex align-items-center justify-content-center"
          v-if="!this.is_trial"
        >
          VIEW MORE
        </router-link>

        <!-- Trial Account -->
        <a
          href="#"
          @click.prevent="showMkwModal()"
          class="button-expertel btn-h48 d-flex align-items-center justify-content-center"
          v-if="this.is_trial"
        > 
          VIEW MORE 
        </a>

      </div>
    </div>

    <!-- [[ UNASSIGNED LINES MODAL ]] -->
    <transition name="fade">
      <div
        id="modal-contact"
        class="modal-expertel container-fluid"
        v-if="show_mkw === true"
      >
        <div class="modal-container container-500">
          <div class="header-modal">
            <button class="close-modal" @click="showMkwModal()">
              <i class="fa fa-times" aria-hidden="true"></i>
            </button>
          </div>
          <div class="body-modal modal-market-watch">
            <div class="justify-content-center">
              <img src="@/assets/img/mkw.svg" alt="IMG" />
              <h2 class="justify-content-center">Subscribe to Expertel iQ</h2>
              <p class="text-center">
                To view these results, please contact your account executive to subscribe to Expertel iQ
              </p>
              <button
                  class="button-expertel"
                  @click="showMkwModal()"
                >
                  CLOSE
              </button>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <!-- [[ UNASSIGNED LINES MODAL ]] -->

  </div>
</template>

<script>
export default {
  props: ["login_data", "id_report"],
  data() {
    return {
      loading: true,
      auditor_charges: [],
      number_charges: 0,
      total_amount: 0,
      is_trial: false,
      show_mkw: false,
    };
  },
  created() {
    if (!Array.isArray(this.login_data)) {
      this.getData();
    }
  },
  methods: {
    getData() {
      const self = this;
      self.is_trial = self.login_data.client.is_trial

      if(this.id_report){
        this.loading = true;
        this.axios({
          method: "get",
          url: `https://api.expertel.ca/api/v1/client/me/reports/${this.id_report}/data?type=optimizer_summary`,
          responseType: "json",
          headers: {
            Authorization: "Bearer " + self.login_data.token
          }
        })
        .then(function(response) {
          self.auditor_charges = response.data.data.validator.items;
          self.number_charges = response.data.data.validator.num_items;

          response.data.data.validator.items.forEach(data => {
            self.total_amount += parseFloat(data.amount.replace("$", ""));
          });

          self.loading = false;
        })
        .catch(error => self.makeToast("ERROR", error, "danger"));
      }else{
        this.loading = false;
      }

    },
    showMkwModal() {
      this.show_mkw = !this.show_mkw;
    },
  },
  makeToast(message, variant = null) {
    this.$bvToast.toast(`${message}`, {
      title: "Error",
      autoHideDelay: 5000,
      appendToast: true,
      variant: variant
    });
  }
};
</script>
