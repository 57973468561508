<template>
  <div class="row">
    <div class="col-12 col-sm-12 px-0">
      <div class="accordion" role="tablist">
        <b-card no-body class="mb-1 px-0 py-0">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <i
              class="fas fa-info-circle ml-2 info-navbar-toggler"
              id="tt-additional-charges"
              :style="{ left: positionInfoToggle + 'px' }"
            ></i>
            <b-navbar-toggle target="additional-charges" class="title-card">
              <template #default="{ expanded }">
                <span id="mainTitle_add_charges"
                  >Additional Charges

                  <b-tooltip target="tt-additional-charges" placement="right">
                    Additional charges are usage-based fees that are charged in
                    addition to your regular monthly service fee.
                  </b-tooltip>
                </span>
                <i class="far fa-angle-up" v-if="expanded"></i>
                <i class="far fa-angle-down" v-else></i>
              </template>
            </b-navbar-toggle>
          </b-card-header>
          <b-collapse
            id="additional-charges"
            accordion="additional-charges"
            role="tabpanel"
          >
            <b-card-body>
              <div class="row">
                <div class="col-12 col-sm-12">
                  <grid-loader
                    class="expertel-loader"
                    color="#ff502d"
                    :loading="loading"
                    :size="20"
                    sizeUnit="px"
                  ></grid-loader>
                </div>
              </div>
              <div v-if="data_completed === true">
                <table class="dashboard-table">
                  <thead>
                    <tr>
                      <th class="d-none d-md-table-cell">Carrier</th>
                      <th>Charge Type</th>
                      <th class="text-center">Percentage</th>
                      <th class="text-center">Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(line, key) in lines.data.slice(0, 5)"
                      :key="key"
                    >
                      <td class="d-none d-md-table-cell">
                        <img
                          :src="
                            `https://api.expertel.ca/dist/images/${line.carrier}.svg`
                          "
                          class="img-fluid carrier-img"
                        />
                      </td>
                      <td>
                        {{ line.description }}
                      </td>
                      <td class="text-center">
                        {{ line.percentage }}
                      </td>
                      <td class="text-center slim-info-td">
                        {{ line.amount }}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <p class="mt-3 mb-0 text-right">
                  <a
                    :href="reportMeta.csv_url"
                    target="_blank"
                    class="link mr-3"
                    ><strong>Download CSV</strong></a
                  >
                  <button class="link" v-b-modal.modalADDCHA>
                    <strong>View All</strong>
                  </button>
                </p>
              </div>
            </b-card-body>
          </b-collapse>
        </b-card>
      </div>
    </div>

    <!-- [[ MODAL ]] -->
    <b-modal id="modalADDCHA" size="xl" centered scrollable class="card">
      <template #modal-header="{ close }">
        <h3 class="font-circular-book mx-auto">Additional Charges</h3>
        <!-- Emulate built in modal header close button action -->
        <b-button size="sm" variant="outline-expertel" @click="close()">
          <i class="fas fa-times    "></i>
        </b-button>
      </template>
      <div class="row">
        <div class="col-12 col-sm-12">
          <!--- [[ TABLE]] -->
          <table class="dashboard-table">
            <thead>
              <tr>
                <th class="d-none d-md-table-cell">Carrier</th>
                <th>Charge Type</th>
                <th class="text-center">Percentage</th>
                <th class="text-center">Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(line, key) in lines.data" :key="key">
                <td class="d-none d-md-table-cell">
                  <img
                    :src="
                      `https://api.expertel.ca/dist/images/${line.carrier}.svg`
                    "
                    class="img-fluid carrier-img"
                  />
                </td>
                <td>
                  {{ line.description }}
                </td>
                <td class="text-center">
                  {{ line.percentage }}
                </td>
                <td class="text-center">
                  <strong>{{ line.amount }}</strong>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <template #modal-footer="{ close }">
        <div class="w-100">
          <button
            aria-label="Close"
            type="button"
            class="float-right button-expertel"
            @click="close()"
          >
            Close
          </button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: ["login_data", "id_report"],
  data() {
    return {
      lines: [],
      data_completed: false,
      loading: true,
      reportMeta: [],
      positionInfoToggle: 0
    };
  },
  watch: {
    id_report: function(newVal) {
      this.getData(newVal);
    }
  },
  mounted() {
    let width = document.getElementById("mainTitle_add_charges").offsetWidth;
    this.positionInfoToggle = width + 15;
  },
  created() {
    if (this.id_report !== "") {
      this.getData(this.id_report);
    }
  },
  methods: {
    getData(id_report) {
      const self = this;
      this.axios({
        method: "get",
        url: `https://api.expertel.ca/api/v1/client/me/reports/${id_report}/data?type=non_business_summary`,
        responseType: "json",
        headers: {
          Authorization: "Bearer " + self.login_data.token
        }
      })
        .then(function(response) {
          self.reportMeta = response.data.meta;
          self.lines = response.data;
          self.data_completed = true;
          self.loading = false;
          //sort((a, b) => a.relative - b.relative)
        })
        .catch(error => (console.log(error), alert(error)));
    }
  }
};
</script>
