<template>
  <div class="row">
    <div class="col-12 col-sm-12 px-0">
      <div class="accordion" role="tablist">
        <b-card no-body class="mb-1 px-0 py-0">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <i
              class="fas fa-info-circle ml-2 mr-3 mr-md-0 info-navbar-toggler"
              id="tt-aditional-charge-summary"
              :style="{ left: positionInfoToggle + 'px' }"
            ></i>
            <b-navbar-toggle
              target="aditional-charge-summary"
              class="title-card"
            >
              <template #default="{ expanded }">
                <span id="mainTitle_addChargesSummary">
                  Additional Charges Summary

                  <b-tooltip
                    target="tt-aditional-charge-summary"
                    placement="right"
                  >
                    Additional charges are usage-based fees that are charged in
                    addition to your regular monthly service fee.
                  </b-tooltip>
                </span>
                <i class="far fa-angle-up" v-if="expanded"></i>
                <i class="far fa-angle-down" v-else></i>
              </template>
            </b-navbar-toggle>
          </b-card-header>
          <b-collapse
            id="aditional-charge-summary"
            accordion="my-accordion"
            role="tabpanel"
          >
            <b-card-body>
              <div class="row">
                <div class="col-12 col-sm-12">
                  <grid-loader
                    class="expertel-loader"
                    color="#ff502d"
                    :loading="loading"
                    :size="20"
                    sizeUnit="px"
                  ></grid-loader>
                </div>
              </div>
              <div v-if="data_completed === true">
                <b-progress
                  class="progress-no-rounded mt-2"
                  :max="100"
                  height="2rem"
                >
                  <b-progress-bar
                    v-for="(line, key) in lines.data"
                    :key="key"
                    :value="line.percentage"
                    :variant="`${variants[key]}`"
                  ></b-progress-bar>
                </b-progress>
                <div class="row mt-4 additional-summary-legend">
                  <div
                    class="col-sm-6 col-lg-4 d-flex align-items-center mb-2"
                    v-for="(line, key) in lines.data"
                    :key="key"
                  >
                    <div :class="variants[key]" class="box-12 mr-2"></div>
                    <p class="mb-0">
                      {{ line.description }}
                      <strong>{{ line.amount }}</strong>
                      <span :class="variants[key]" class="text">
                        {{ line.percentage }}</span
                      >
                    </p>
                  </div>
                </div>
              </div>
            </b-card-body>
          </b-collapse>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["login_data", "id_report"],
  data() {
    return {
      lines: [],
      data_completed: false,
      variants: [
        "variant-orange",
        "variant-green-1",
        "variant-blue-1",
        "variant-purple-1",
        "variant-green-2",
        "variant-purple-2",
        "variant-purple-3",
        "variant-orange"
      ],
      loading: true,
      positionInfoToggle: 0
    };
  },
  watch: {
    id_report: function(newVal) {
      this.getData(newVal);
    }
  },
  created() {
    if (this.id_report !== "") {
      this.getData(this.id_report);
    }
  },
  mounted() {
    let width = document.getElementById("mainTitle_addChargesSummary")
      .offsetWidth;
    if (window.outerWidth < 420) {
      this.positionInfoToggle = width - 6;
    } else {
      this.positionInfoToggle = width + 15;
    }
  },
  methods: {
    getData(id_report) {
      const self = this;
      this.axios({
        method: "get",
        url: `https://api.expertel.ca/api/v1/client/me/reports/${id_report}/data?type=non_business_summary`,
        responseType: "json",
        headers: {
          Authorization: "Bearer " + self.login_data.token
        }
      })
        .then(function(response) {
          self.lines = response.data;
          self.data_completed = true;
          self.loading = false;
          //sort((a, b) => a.relative - b.relative)
        })
        .catch(error => (console.log(error), alert(error)));
    }
  }
};
</script>

<style lang="scss" scoped>
@media screen and (max-width: 767px) {
  #mainTitle_addChargesSummary {
    max-width: 85%;
  }
}
</style>
