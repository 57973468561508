<template>
  <div class="card line-count-overview">
    <div class="row">
      <div class="col-12 col-sm-12">
        <h2>Line Count Overview</h2>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-sm-12">
        <grid-loader
          class="expertel-loader"
          color="#ff502d"
          :loading="loading"
          :size="20"
          sizeUnit="px"
        ></grid-loader>
      </div>
    </div>
    <div class="row" v-if="complete_lc === true">
      <div class="col-6 col-sm-6 col-lg-3 mt-4 text-center br-1">
        <p class="title">ACTIVE</p>
        <p class="cost">{{ line_count.data.num_active }}</p>
      </div>
      <div class="col-6 col-sm-6 col-lg-3 mt-4 text-center br-lg-1">
        <p class="title">CANCELLED</p>
        <p class="cost">{{ line_count.data.num_cancelled }}</p>
      </div>
      <div class="col-6 col-sm-6 col-lg-3 mt-4 text-center br-1">
        <p class="title">SUSPENDED</p>
        <p class="cost">{{ line_count.data.num_suspended }}</p>
      </div>
      <div class="col-6 col-sm-6 col-lg-3 mt-4 text-center">
        <p class="title">UNUSED</p>
        <p class="cost">{{ line_count.data.num_dormant }}</p>
      </div>
      <div class="col-6 col-sm-12 mt-3 text-center">
        <p class="mb-0 alert-message">
          Total <strong>{{ line_count.data.total_lines }}</strong> users
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["login_data", "id_report"],
  data() {
    return {
      line_count: [],
      complete_lc: false,
      loading: true
    };
  },
  async created() {
    const self = this;
    await this.axios({
      method: "get",
      url: `https://api.expertel.ca/api/v1/client/me/reports/${this.id_report}/data?type=line_count`,
      responseType: "json",
      headers: {
        Authorization: "Bearer " + self.login_data.token
      }
    })
      .then(function(response) {
        self.line_count = response.data;
        self.complete_lc = true;
        self.loading = false;
      })
      .catch(error => (console.log(error), alert(error)));
  }
};
</script>

<style lang="scss" scoped>
.line-count-overview {
  .title {
    letter-spacing: 2px;
  }
  .cost {
    font-family: "Circular", sans-serif;
    font-weight: bold;
    font-size: 32px;
  }
  .alert-message {
    color: #ff502d;
    margin-bottom: 0;
    i {
      margin-right: 0.5rem;
    }
  }
  .br-0 {
    border-right: 0;
  }
}

@media screen and (max-width: 1440px) {
  .line-count-overview {
    .title {
      letter-spacing: 0;
    }
    .cost {
      font-size: 32px;
    }
  }
}
@media screen and (max-width: 1439px) and (min-width: 991px) {
  .line-count-overview {
    .title {
      letter-spacing: 0;
    }
    .cost {
      font-size: 24px;
    }
  }
}
@media screen and (max-width: 767px) {
  .line-count-overview {
    .title {
      letter-spacing: 0;
    }
    .cost {
      font-size: 28px;
    }
  }
}
</style>
