<template>
  <div class="card">
    <div class="row">
      <div class="col-12 col-sm-12">
        <h2>Billing Overview</h2>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-sm-12">
        <grid-loader
          class="expertel-loader"
          color="#ff502d"
          :loading="loading"
          :size="20"
          sizeUnit="px"
        ></grid-loader>
      </div>
    </div>
    <div class="row" v-if="complete_bo === true">
      <div class="col-12 col-sm-12 col-md-6 mt-4 text-center current-balance">
        <div class="row">
          <div class="col-12 col-sm-12 px-0 br-lg-1">
            <p class="title">CURRENT BALANCE</p>
            <p class="cost" v-if="billing_details.data.total !== undefined">
              {{ billing_details.data.total }}
            </p>
          </div>
        </div>
        <p class="alert-message mt-3">
          <i class="fas fa-arrow-up"></i>
          <strong>{{ billing_details.data.total_variation_txt }}</strong> more
          than last month.
        </p>
      </div>
      <div class="col-12 col-sm-12 col-md-6 mt-4 text-center avg-cost-user">
        <div class="row">
          <div class="col-12 col-sm-12 px-0">
            <p class="title">AVG COST / USER</p>
            <p class="cost">{{ billing_details.data.average_per_user_txt }}</p>
          </div>
        </div>
        <p class="alert-message mt-3">
          <i class="fas fa-arrow-up"></i>
          <strong>{{ billing_details.data.average_user_variation_txt }}</strong>
          more than last month.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["login_data", "id_report"],
  data() {
    return {
      billing_details: [],
      complete_bo: false,
      loading: true
    };
  },
  async created() {
    const self = this;
    await this.axios({
      method: "get",
      url: `https://api.expertel.ca/api/v1/client/me/reports/${this.id_report}/data?type=billing_details`,
      responseType: "json",
      headers: {
        Authorization: "Bearer " + self.login_data.token
      }
    })
      .then(function(response) {
        self.billing_details = response.data;
        self.complete_bo = true;
        self.loading = false;
      })
      .catch(error => (console.log(error), alert(error)));
  }
};
</script>

<style lang="scss" scoped>
.current-balance {
  .title {
    letter-spacing: 2px;
  }
  .cost {
    font-family: "Circular", sans-serif;
    font-weight: bold;
    font-size: 32px;
  }
  .alert-message {
    color: #ff502d;
    margin-bottom: 0;
    i {
      margin-right: 0.5rem;
    }
  }
}
.avg-cost-user {
  .title {
    letter-spacing: 2px;
  }
  .cost {
    font-family: "Circular", sans-serif;
    font-weight: bold;
    font-size: 32px;
  }
  .alert-message {
    color: #ff502d;
    margin-bottom: 0;
    i {
      margin-right: 0.5rem;
    }
  }
}
@media screen and (max-width: 1440px) {
  .avg-cost-user,
  .current-balance {
    .title {
      letter-spacing: 0;
    }
    .cost {
      font-size: 32px;
    }
  }
}
@media screen and (max-width: 1439px) and (min-width: 991px) {
  .avg-cost-user,
  .current-balance {
    .title {
      letter-spacing: 0;
    }
    .cost {
      font-size: 24px;
    }
  }
}
@media screen and (max-width: 767px) {
  .avg-cost-user,
  .current-balance {
    .title {
      letter-spacing: 0;
    }
    .cost {
      font-size: 32px;
    }
  }
}
</style>
