<template>
  <div class="card bg-white">
    <div class="row">
      <div class="col-12 col-sm-12">
        <h2>Active Lines</h2>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-sm-12">
        <grid-loader
          class="expertel-loader"
          color="#ff502d"
          :loading="loading"
          :size="20"
          sizeUnit="px"
        ></grid-loader>
      </div>
    </div>
    <div class="row pt-4" v-if="loading === false">
      <div class="col-12 col-sm-12">
        <highcharts
          class="marketWatchChart"
          :options="stockOptions"
        ></highcharts>
      </div>
    </div>
    <div class="row mt-24px">
      <div class="col-12 col-sm-12 text-right">
        <p class="mb-0">
          <button class="link" v-b-modal.modalActLines>
            <strong>Show more</strong>
          </button>
        </p>
      </div>
    </div>

    <!-- [[ MODAL ]] -->
    <b-modal id="modalActLines" size="xl" centered>
      <template #modal-header="{ close }">
        <h3 class="font-circular-book mx-auto">Active Lines</h3>
        <!-- Emulate built in modal header close button action -->
        <b-button size="sm" variant="outline-expertel" @click="close()">
          <i class="fas fa-times    "></i>
        </b-button>
      </template>
      <div class="row">
        <div class="col-12 col-sm-12">
          <highcharts class="optimizerChart" :options="modalGraph"></highcharts>
        </div>
      </div>
      <template #modal-footer="{ close }">
        <div class="w-100">
          <button
            aria-label="Close"
            type="button"
            class="float-right button-expertel"
            @click="close()"
          >
            Close
          </button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: ["id_report", "login_data"],
  data() {
    return {
      stockOptions: {},
      activeLines: [],
      modalGraph: {},
      loading: true
    };
  },
  created() {
    if (this.id_report !== "") {
      this.getData(this.id_report);
    }
  },
  watch: {
    id_report: function(newVal) {
      this.getData(newVal);
    }
  },
  methods: {
    async getData(id_report) {
      const self = this;

      //REQUEST ACTIVE LINES DATA
      await this.axios({
        method: "get",
        url: `https://api.expertel.ca/api/v1/client/me/reports/${id_report}/data?type=monthly_users`,
        responseType: "json",
        headers: {
          Authorization: "Bearer " + self.login_data.token
        }
      })
        .then(function(response) {
          // SAVE DATA
          self.activeLines = response.data;
          // STOP LOADER
          self.loading = false;
        })
        //.catch(error => (console.log(error)));
        .catch(error => this.makeToast("ERROR", error, "danger"));

      // ORDER xAxis DATA AND yAxis DATA OF GRAPHIC
      let dataUser = [],
        catTT = [];
      this.activeLines.data.forEach(data => {
        catTT.push(data[0]);
        dataUser.push([data[1]]);
      });

      // GRAPHIC OF ACTIVE LINES
      this.stockOptions = {
        chart: {
          type: "column",
          height: 250
        },
        credits: {
          enabled: false
        },
        title: {
          text: ""
        },
        xAxis: {
          categories:
            catTT.length > 6
              ? catTT.slice(catTT.length - 6, catTT.length)
              : catTT,
          tickmarkPlacement: "on",
          title: {
            enabled: false
          },
          labels: {
            style: {
              fontFamily: "'Circular Book', sans-serif",
              fontSize: "1.35em",
              color: "#343f63"
            }
          }
        },
        yAxis: {
          min: 0,
          title: {
            text: ""
          },
          labels: {
            enabled: true,
            formatter() {
              return (
                "<span>" +
                this.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
                "</span>"
              );
            },
            style: {
              fontFamily: "'Circular Book', sans-serif",
              fontSize: "1.35em",
              color: "#343f63"
            }
          }
        },
        legend: {
          enabled: false
        },
        tooltip: {
          formatter() {
            return (
              "<strong>" +
              this.y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
              " Lines</strong>"
            );
          }
        },
        series: [
          {
            name: "",
            color: "rgba(239, 81, 53, 0.7)",
            borderRadius: 5,
            data:
              dataUser.length > 6
                ? dataUser.slice(dataUser.length - 6, dataUser.length)
                : dataUser
          }
        ],
        responsive: {
          rules: [
            {
              condition: {
                minWidth: 10
              },
              chartOptions: {
                yAxis: [
                  {
                    labels: {
                      enabled: false
                    }
                  }
                ]
              }
            }
          ]
        }
      };

      // GRAPHIC MODAL VIEW ALL
      this.modalGraph = {
        chart: {
          type: "column",
          height: "250px"
        },
        credits: {
          enabled: false
        },
        title: {
          text: ""
        },
        xAxis: {
          categories:
            catTT.length > 12
              ? catTT.slice(catTT.length - 12, catTT.length)
              : catTT,
          tickmarkPlacement: "on",
          title: {
            enabled: false
          },
          labels: {
            style: {
              fontFamily: "'Circular', sans-serif",
              fontSize: "1.35em",
              color: "#343f63"
            }
          }
        },
        yAxis: {
          min: 0,
          title: {
            text: ""
          },
          labels: {
            formatter() {
              return (
                "<span>" +
                this.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
                "</span>"
              );
            },
            style: {
              fontFamily: "'Circular', sans-serif",
              fontSize: "1.35em",
              color: "#343f63"
            }
          }
        },
        legend: {
          enabled: false
        },
        tooltip: {
          formatter() {
            return (
              "<strong>" +
              this.y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
              " Lines</strong>"
            );
          }
        },
        series: [
          {
            name: "",
            color: "rgba(239, 81, 53, 0.7)",
            borderRadius: 5,
            data:
              dataUser.length > 12
                ? dataUser.slice(dataUser.length - 12, dataUser.length)
                : dataUser
          }
        ]
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.marketWatchChart {
  width: 100%;
  margin: 0 auto;
}
</style>
